import { findKey } from "lodash";
import { isClient } from "common/helper";

const config = {
  production: {
    HOST: "*",
    API_ENDPOINT: "/graphql",
    DJANGO_API_ENDPOINT: "https://satverbal.satdetector.com/api",
  },
  staging: {
    HOST: "",
    API_ENDPOINT: "",
  },
  local: {
    HOST: "http://localhost:3000",
    API_ENDPOINT: "http://localhost:20202/graphql",
    REST_API_ENDPOINT: "https://admin-satverbal.satdetector.com/",
    DJANGO_API_ENDPOINT: "https://satverbal.satdetector.com/api",
  },
};

const getEnvByHost = () =>
  isClient ? findKey(config, { HOST: window.location.origin }) : null;

export const env = getEnvByHost() || process.env.APP_ENV || "production";

export default config[env];
