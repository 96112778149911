import { compose, withHooks, withProps } from "enhancers";
import { FieldArray } from "formik";

const enhancer = compose(
  withHooks((props, hooks) => {
    const { name, component, ...rest } = props;
    const { useMemo } = hooks;

    const customComponent = useMemo(() => {
      return withProps(() => rest)(component);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rest)]);

    return {
      component: customComponent,
      name,
      ...rest,
    };
  })
);

export default enhancer(FieldArray);
