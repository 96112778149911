import { compose, withFormik, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, Box, Button, Form, Notification } from "components";
import {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  toggleExpandedForAll,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { TextField } from "@material-ui/core";
import { getErrorMessage, gql, notifyError, paths } from "utils/helper";
import { Autocomplete } from "@material-ui/lab";
import { TextField as TextFieldMu } from "@material-ui/core";
import { find, isEmpty, range } from "lodash";
import SortableTree from "components/common/SortableTree";

const TagPage = (props) => (
  <PageContent
    title="Tag"
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
    paper={false}
  >
    <Form>
      <Box height="fit-content">
        <SortableTree
          treeData={props.treeData}
          onChange={props.onChange}
          isVirtualized={false}
          canDrag={({ path }) => path.length > 1}
          canDrop={(node) => node.nextPath.length === node.prevPath.length}
          generateNodeProps={({ node, path, ...rest }) => {
            return {
              title: (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width={500}
                >
                  {path.length > 1 ? (
                    <>
                      <TextField
                        value={node.code ?? ""}
                        onChange={props.editText(path, node, "code")}
                        onBlur={props.editText(path, node, "code")}
                        size="small"
                        variant="standard"
                        type="number"
                        style={{
                          width: "40px",
                          marginRight: "4px",
                          textAlign: "center",
                        }}
                        placeholder="รหัส"
                        disabled={
                          !node.canDelete &&
                          node.canDelete !== null &&
                          node.canDelete !== undefined
                        }
                      />
                      <TextField
                        value={node.title ?? ""}
                        onChange={props.editText(path, node, "title")}
                        onBlur={props.editText(path, node, "title")}
                        size="small"
                        variant="standard"
                        placeholder="ชื่อ"
                        style={{
                          flex: "1",
                          marginRight: "4px",
                        }}
                      />
                      {isEmpty(node.children) && (
                        <Autocomplete
                          style={{ marginTop: "-3px" }}
                          options={props.difficultyLevelOptions}
                          disableClearable
                          getOptionLabel={(option) => {
                            const matchOption = find(
                              props.difficultyLevelOptions,
                              {
                                value: option,
                              }
                            )?.label;
                            return matchOption ?? option.label ?? "";
                          }}
                          getOptionSelected={(option) =>
                            option.value === node.difficultyLevel
                          }
                          onChange={props.editSelect(
                            path,
                            node,
                            "difficultyLevel"
                          )}
                          value={
                            node.difficultyLevel !== null ||
                            node.difficultyLevel !== undefined
                              ? node.difficultyLevel
                              : []
                          }
                          renderInput={(params) => (
                            <TextFieldMu
                              {...params}
                              variant="standard"
                              fullWidth
                              placeholder="ระดับความยาก"
                            />
                          )}
                        />
                      )}
                    </>
                  ) : (
                    node.title
                  )}
                  <Box display="flex">
                    <Button startIcon="add" onClick={props.addNode(path)} />
                    {path.length > 1 && (
                      <Button
                        disabled={
                          !node.canDelete &&
                          node.canDelete !== null &&
                          node.canDelete !== undefined
                        }
                        startIcon="trash"
                        onClick={props.removeNode(path)}
                      />
                    )}
                  </Box>
                </Box>
              ),
            };
          }}
        />
      </Box>
      <Button type="submit" color="primary" width={74} mt={10}>
        บันทึก
      </Button>
    </Form>
  </PageContent>
);

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export const API = {
  FETCH_TAGS: gql`
    query FETCH_TAGS($input: TagsInput!) {
      tags(input: $input) {
        configs
      }
    }
  `,
  UPDATE_TAGS: gql`
    mutation UPDATE_TAGS($configs: JSON, $course: String!) {
      updateTags(input: { configs: $configs, course: $course }) {
        configs
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      configs: [{ title: "ทั้งหมด", children: [] }],
    }),
  }),
  withHooks((props, hooks) => {
    const { values, setFieldValue, setValues } = props;
    const {
      useCallback,
      useMemo,
      useHandleSubmit,
      useQuery,
      useMutation,
      useEffect,
      useUrlPath,
    } = hooks;
    const { courseId } = useUrlPath();
    const classes = useStyles();

    const { loading, data, error, refetch } = useQuery(API.FETCH_TAGS, {
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          course: courseId,
        },
      },
    });
    const [updateTags] = useMutation(API.UPDATE_TAGS);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const breadcrumbs = useMemo(
      () => [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: "แท็ก" },
      ],
      []
    );

    const tags = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        return null;
      }
      return isEmpty(data.tags.configs[0]?.id)
        ? { configs: [{ title: "ทั้งหมด", children: [] }] }
        : data.tags;
    }, [loading, data, error]);

    useEffect(() => {
      if (loading) {
        Backdrop.open({ delay: 0 });
      } else {
        Backdrop.close();
      }
    }, [loading]);

    useEffect(() => {
      if (!isEmpty(tags)) {
        const newTreeData = toggleExpandedForAll({
          treeData: tags.configs,
          expanded: true,
        });
        setValues({ ...tags, configs: newTreeData });
      }
    }, [setValues, tags]);

    useHandleSubmit(async (values) => {
      Backdrop.open();
      let responseError = null;
      const validateTags = (configs, deforeCode) => {
        configs.forEach((item) => {
          if (isEmpty(item.code) || isEmpty(item.title)) {
            responseError = `กรุณากรอกข้อมูลให้ครบถ้วน ลูกของ ${deforeCode}`;
            return null;
          }

          if (item.children.length > 0) {
            validateTags(item.children, `${deforeCode} > ${item.code}`);
          } else {
            if (
              item.difficultyLevel === null ||
              item.difficultyLevel === undefined
            ) {
              responseError = `กรุณากรอกข้อมูลให้ครบถ้วน ลูกของ ${deforeCode}`;
            }
            return null;
          }
        });
        return responseError;
      };

      try {
        const responseError = validateTags(
          values.configs[0].children,
          "ทั้งหมด"
        );
        if (responseError) {
          Notification.error("กรุณากรอกข้อมูลให้ครบ");
          Backdrop.close();
          return null;
        }
        await updateTags({
          variables: { ...values, course: courseId },
        });

        Notification.success("แก้ไขข้อมูลสำเร็จ");
        Backdrop.close();
      } catch (e) {
        const message = e.message ?? "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน";
        Notification.error(message);
        Backdrop.close();
      }
    }, []);

    const treeData = useMemo(() => {
      return values?.configs;
    }, [values?.configs]);

    const onChange = useCallback(
      (treeData) => {
        setFieldValue("configs", treeData);
      },
      [setFieldValue]
    );

    const addNode = useCallback(
      (path) => () => {
        const newTreedata = addNodeUnderParent({
          treeData: treeData,
          parentKey: path[path.length - 1],
          expandParent: true,
          minimumTreeIndex: treeData.length,
          newNode: { title: "", children: [] },
          getNodeKey: ({ treeIndex }) => treeIndex,
        }).treeData;
        setFieldValue("configs", newTreedata);
      },
      [treeData, setFieldValue]
    );

    const removeNode = useCallback(
      (path) => () => {
        const newTreedata = removeNodeAtPath({
          treeData: treeData,
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
        });
        setFieldValue("configs", newTreedata);
      },
      [treeData, setFieldValue]
    );

    const editText = useCallback(
      (path, node, fieldName) => (e) => {
        const newTreedata = changeNodeAtPath({
          treeData: treeData,
          path,
          newNode: { ...node, [fieldName]: e.target.value },
          getNodeKey: ({ treeIndex }) => treeIndex,
        });
        setFieldValue("configs", newTreedata);
      },
      [treeData, setFieldValue]
    );

    const editSelect = useCallback(
      (path, node, fieldName) => (e, value) => {
        const newTreedata = changeNodeAtPath({
          treeData: treeData,
          path,
          newNode: { ...node, [fieldName]: value?.value ?? "" },
          getNodeKey: ({ treeIndex }) => treeIndex,
        });
        setFieldValue("configs", newTreedata);
      },
      [treeData, setFieldValue]
    );

    const difficultyLevelOptions = useMemo(
      () =>
        range(0, 11).map((number) => ({
          label: number.toString(),
          value: number,
        })),
      []
    );
    return {
      classes,
      treeData,
      onChange,
      addNode,
      removeNode,
      editText,
      editSelect,
      difficultyLevelOptions,
      breadcrumbs,
    };
  })
);

export default enhancer(TagPage);
