import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Field,
  TextField,
  Button,
  Notification,
  Select,
  FieldArray,
  Divider,
  Typography,
  LatexEditor,
  FileUploadList,
  Backdrop,
} from "components";
import { gql, notifyError, paths } from "utils/helper";
import LatexList from "components/advance/LatexList";
import { cloneDeep, find, uniq, uniqBy, map as lodashMap } from "lodash";
import {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  map,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { Autocomplete } from "@material-ui/lab";
import { TextField as TextFieldMu } from "@material-ui/core";
import SortableTree from "components/common/SortableTree";
import SwitchInput from "components/common/SwitchInput";

export const QuestionNewPage = (props) =>
  props.initialized ? (
    <PageContent
      title={props.title}
      breadcrumbs={props.breadcrumbs}
      pageActions={props.pageActions}
    >
      {/* <Form> */}
      <Box display="flex" flexDirection="column">
        {/* กรณี published = false */}
        <Box display={props.canEdit ? "flex" : "none"} flexDirection="column">
          <Field
            name="name"
            component={TextField}
            label="ชื่อ"
            width={512}
            helperText="โปรดระบุ"
          />
          <Field
            name="paragraphId"
            component={Select}
            options={props.paragraphOptions}
            label="บทความ"
            width={512}
            mt={6}
          />
          <Field
            name="type"
            component={Select}
            options={props.typeOptions}
            label="ประเภทคำถาม"
            width={512}
            mt={6}
            helperText="โปรดระบุเมื่อเปิดใช้งาน"
          />
          <Field
            name="category"
            component={Select}
            options={props.categoryOptions}
            multiple
            freeSolo
            forceFix
            label="หมวดหมู่"
            width={512}
            mt={6}
          />
        </Box>

        {/* กรณี published = true */}
        <Box display={props.canEdit ? "none" : "flex"} flexDirection="column">
          <Field
            name="name"
            component={TextField}
            label="ชื่อ"
            width={512}
            disabled
            helperText="โปรดระบุ"
          />
          <Field
            name="paragraphId"
            component={Select}
            options={props.paragraphOptions}
            label="บทความ"
            width={512}
            mt={6}
            disabled
          />
          <Field
            name="type"
            component={Select}
            options={props.typeOptions}
            label="ประเภทคำถาม"
            width={512}
            mt={6}
            disabled
            helperText="โปรดระบุเมื่อเปิดใช้งาน"
          />
          <Field
            name="category"
            component={Select}
            options={props.categoryOptions}
            label="หมวดหมู่"
            width={512}
            mt={6}
            disabled
            multiple
            freeSolo
            forceFix
          />
        </Box>

        <Divider my={6} />

        <Box display={props.canEdit ? "none" : "flex"} flexDirection="column">
          <Typography variant="h4" flex={1}>
            Question Hierachy
          </Typography>
          <Box marginTop="24px">
            {props.values.modelQuestionId && (
              <Box>
                <Typography variant="h5" flex={1}>
                  Parent Question:
                </Typography>
                <QuestionButton
                  questionId={props.values.modelQuestionId}
                  questionName={props.getQuestionName(
                    props.values.modelQuestionId
                  )}
                />
              </Box>
            )}

            {props.values.childrenQuestionIds &&
              props.values.childrenQuestionIds.length > 0 && (
                <Box mt={2}>
                  <Typography variant="h5" flex={1}>
                    Children Question(s):
                  </Typography>
                  {props.values.childrenQuestionIds.map((questionId) => (
                    <QuestionButton
                      key={questionId}
                      questionId={questionId}
                      questionName={props.getQuestionName(questionId)}
                    />
                  ))}
                </Box>
              )}
          </Box>
        </Box>

        <Divider my={6} />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h4" flex={1}>
              แท็ก
            </Typography>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.54)">
              โปรดระบุอย่างน้อย 1 tag เมื่อเปิดใช้งาน
            </Typography>
          </Box>

          <Box display={props.canEdit ? "flex" : "none"} alignItems="center">
            <Field
              name="prefillTags"
              component={Select}
              options={props.prefillOptions}
              width={400}
              size="small"
              mr={4}
            />

            <Button
              color="primary"
              width={74}
              p={0}
              onClick={props.prefillTags}
              disabled={!props.values.prefillTags}
            >
              Pre fill
            </Button>
          </Box>

          <Box display={props.canEdit ? "none" : "flex"} alignItems="center">
            <Field
              name="prefillTags"
              component={Select}
              options={props.prefillOptions}
              width={400}
              size="small"
              mr={4}
              disabled
            />

            <Button
              color="primary"
              width={74}
              p={0}
              onClick={props.prefillTags}
              disabled
            >
              Pre fill
            </Button>
          </Box>
        </Box>
        <SortableTree
          treeData={props.treeData}
          onChange={props.onTreeChange}
          isVirtualized={false}
          canDrag={({ path }) => path.length > 1 && props.canEdit}
          canDrop={(node) =>
            node.nextPath.length === node.prevPath.length && props.canEdit
          }
          maxDepth={4}
          generateNodeProps={({
            node,
            path,
            lowerSiblingCounts,
            parentNode,
            ...rest
          }) => {
            return {
              title: (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width={500}
                >
                  {path.length !== 4 && node.title}
                  {path.length === 4 && (
                    <Autocomplete
                      key={props.tagsOptions.length}
                      options={props.tagsOptions}
                      getOptionLabel={props.getOptionLabel}
                      getOptionSelected={(option) =>
                        option.value === node.title
                      }
                      fullWidth
                      disabled={!props.canEdit}
                      onChange={props.editText(path, node, "title")}
                      value={node.title ? node.title : []}
                      renderInput={(params) => (
                        <TextFieldMu {...params} variant="standard" fullWidth />
                      )}
                    />
                  )}
                  <Box display="flex" marginLeft={2}>
                    {path.length !== 4 && props.canEdit && (
                      <Button
                        startIcon="add"
                        onClick={props.addNode(
                          node,
                          parentNode,
                          lowerSiblingCounts,
                          path
                        )}
                      />
                    )}
                    {path.length !== 1 && props.canEdit && (
                      <Button
                        startIcon="trash"
                        onClick={props.removeNode(path)}
                      />
                    )}
                  </Box>
                </Box>
              ),
            };
          }}
        />

        <Divider my={6} />

        {/* กรณี published = false */}
        <Box
          Box
          display={props.canEdit ? "flex" : "none"}
          flexDirection="column"
          mb={4}
        >
          <Field
            name="description"
            component={LatexEditor}
            label="คำถาม"
            editorMaxHeight="501.5px"
            editorMaxWidth="710px"
            helperText="โปรดระบุเมื่อเปิดใช้งาน (ขนาดของคำถามที่แนะนำไม่ควรเกิน 678x947 px โปรดใช้ในการอ้างอิง)"
          />
          <Divider my={6} />
          <Box mb={2}>
            <Typography variant="h4" flex={1}>
              คำตอบ
            </Typography>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.54)">
              โปรดระบุเมื่อเปิดใช้งาน (ขนาดของคำตอบแต่ละข้อที่แนะนำไม่ควรเกิน
              668x947 px โปรดใช้ในการอ้างอิง)
            </Typography>
          </Box>
          {props.values?.type === "mcqs" && (
            <FieldArray
              name="answerConfig.mcqs.choices"
              component={LatexList}
            />
          )}
          {props.values?.type === "Grid-in" && (
            <>
              <Field
                component={Select}
                name="answerConfig.gridIn.labelList"
                label="ตัวเลือก grid-in"
                fullWidth
                options={props.labelListOptions}
                multiple
                freeSolo
                forceFix
                onChange={props.selectLabelList}
                helperText="โปรดระบุเมื่อเปิดใช้งาน และแต่ละ tag ห้ามเกิน 1 ตัวอักษร"
              />
              <Box display="flex" mt={4}>
                <Field
                  component={TextField}
                  name="answerConfig.gridIn.labelLength"
                  label="ความยาวสูงสุดของคำตอบ"
                  type="number"
                  mr={2}
                  helperText="โปรดระบุเมื่อเปิดใช้งาน"
                />
                <Field
                  component={Select}
                  name="answerConfig.gridIn.answerList"
                  label="คำตอบ"
                  fullWidth
                  multiple
                  freeSolo
                  forceFix
                  helperText="โปรดระบุเมื่อเปิดใช้งาน และต้องเป็นคำตอบที่เป็นไปได้ตามค่าที่กำหนดไว้ใน grid-in และความยาวสูงสุดตามที่ระบุ"
                />
              </Box>
            </>
          )}
          <Divider my={6} />
          <Field
            name="hardAnswer"
            component={LatexEditor}
            label="เฉลย"
            editorMaxWidth="723px"
            helperText="โปรดระบุเมื่อเปิดใช้งาน (ขนาดของเฉลยที่แนะนำไม่ควรเกิน 678x900 px โปรดใช้ในการอ้างอิง)"
          />
          <Divider my={6} />
          <FieldArray
            component={FileUploadList}
            name="documents"
            title="เอกสาร"
            addButtonLabel="เพิ่มเอกสาร"
            helperText="โปรด Upload File ขนาดไม่เกิน 5MB"
          />
        </Box>

        {/* กรณี published = true */}
        <Box
          display={props.canEdit ? "none" : "flex"}
          flexDirection="column"
          mb={4}
        >
          <Field
            name="description"
            component={LatexEditor}
            label="คำถาม"
            disabled
            editorMaxHeight="501.5px"
            editorMaxWidth="710px"
            helperText="โปรดระบุเมื่อเปิดใช้งาน (ขนาดของคำถามที่แนะนำไม่ควรเกิน 678x947 px โปรดใช้ในการอ้างอิง)"
          />
          <Divider my={6} />
          <Box mb={2}>
            <Typography variant="h4" flex={1}>
              คำตอบ
            </Typography>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.54)">
              โปรดระบุเมื่อเปิดใช้งาน (ขนาดของคำตอบแต่ละข้อที่แนะนำไม่ควรเกิน
              668x947 px โปรดใช้ในการอ้างอิง)
            </Typography>
          </Box>
          {props.values?.type === "mcqs" && (
            <FieldArray
              name="answerConfig.mcqs.choices"
              component={LatexList}
              disabled
            />
          )}
          {props.values?.type === "Grid-in" && (
            <>
              <Field
                component={Select}
                name="answerConfig.gridIn.labelList"
                label="ตัวเลือก grid-in"
                fullWidth
                options={props.labelListOptions}
                multiple
                freeSolo
                forceFix
                onChange={props.selectLabelList}
                disabled
                helperText="โปรดระบุเมื่อเปิดใช้งาน และแต่ละ tag ห้ามเกิน 1 ตัวอักษร"
              />
              <Box display="flex" mt={4}>
                <Field
                  component={TextField}
                  name="answerConfig.gridIn.labelLength"
                  label="ความยาวสูงสุดของคำตอบ"
                  type="number"
                  mr={2}
                  disabled
                  helperText="โปรดระบุเมื่อเปิดใช้งาน"
                />
                <Field
                  component={Select}
                  name="answerConfig.gridIn.answerList"
                  label="คำตอบ"
                  fullWidth
                  multiple
                  freeSolo
                  forceFix
                  disabled
                  helperText="โปรดระบุเมื่อเปิดใช้งาน และต้องเป็นคำตอบที่เป็นไปได้ตามค่าที่กำหนดไว้ใน grid-in และความยาวสูงสุดตามที่ระบุ"
                />
              </Box>
            </>
          )}
          <Divider my={6} />
          <Field
            name="hardAnswer"
            component={LatexEditor}
            label="เฉลย"
            disabled
            editorMaxWidth="723px"
            helperText="โปรดระบุเมื่อเปิดใช้งาน (ขนาดของเฉลยที่แนะนำไม่ควรเกิน 678x900 px โปรดใช้ในการอ้างอิง)"
          />
          <Divider my={6} />
          <FieldArray
            component={FileUploadList}
            name="documents"
            title="เอกสาร"
            addButtonLabel="เพิ่มเอกสาร"
            helperText="โปรด Upload File ขนาดไม่เกิน 5MB"
          />
        </Box>
        <Divider my={6} />
        <Box mb={6}>
          <Field
            name="published"
            component={SwitchInput}
            label="ใช้งาน (กดปุ่มบันทึกด้านล่างอีกครั้งเพื่อยืนยัน)"
          />
        </Box>
        <Button color="primary" width={74} p={0} onClick={props.submit}>
          บันทึก
        </Button>
      </Box>
      {/* </Form> */}
    </PageContent>
  ) : null;

export const API = {
  CREATE_QUESTION: gql`
    mutation CREATE_QUESTION(
      $name: String
      $type: String
      $description: String
      $answerConfig: JSON
      $tagInfo: JSON
      $hardAnswer: String
      $category: [String!]
      $paragraphId: String
      $documents: [Upload!]
      $published: Boolean
      $course: String!
    ) {
      createQuestion(
        input: {
          name: $name
          type: $type
          description: $description
          answerConfig: $answerConfig
          tagInfo: $tagInfo
          hardAnswer: $hardAnswer
          category: $category
          paragraphId: $paragraphId
          documents: $documents
          published: $published
          course: $course
        }
      ) {
        question {
          id
          name
          type
          description
          paragraphId
          answerConfig
          tagInfo
          createdAt
          updatedAt
          hardAnswer
          category
          paragraphId
        }
      }
    }
  `,
  FETCH_TAGS: gql`
    query FETCH_TAGS($input: TagsInput!) {
      tags(input: $input) {
        configs
      }
    }
  `,
  FETCH_QUESTIONS: gql`
    query FETCH_QUESTIONS(
      $page: Float
      $pageSize: Float
      $filters: JSON
      $sorts: JSON
    ) {
      questions(
        input: { filters: $filters, sorts: $sorts }
        paginate: { page: $page, pageSize: $pageSize }
      ) {
        questions {
          id
          answerConfig
          category
          tagInfo
          type
          name
          modelQuestionId
          childrenQuestionIds
        }
      }
    }
  `,
  FETCH_PARAGRAPHS: gql`
    query FETCH_PARAGRAPHS($name: String, $course: String) {
      paragraphs(input: { name: $name, course: $course }) {
        id
        name
      }
    }
  `,
};
const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      tagInfo: { list: [{ title: "ทั้งหมด", children: [] }] },
      answerConfig: {},
      category: [],
      modelQuestionId: "",
      childrenQuestionIds: [],
      published: true,
    }),
  }),
  withHooks((props, hooks) => {
    const { values, setFieldValue } = props;
    const {
      useMutation,
      useMemo,
      useHandleSubmit,
      useQuery,
      useCallback,
      useEffect,
      useUrlPath,
    } = hooks;
    const { courseId } = useUrlPath();
    const fetchTags = useQuery(API.FETCH_TAGS, {
      variables: {
        input: {
          course: courseId,
        },
      },
    });
    const fetchParagraphs = useQuery(API.FETCH_PARAGRAPHS, {
      variables: {
        course: courseId,
      },
    });
    const [createQuestion] = useMutation(API.CREATE_QUESTION);
    const fetchAllQuestions = useQuery(API.FETCH_QUESTIONS, {
      variables: {
        filters: [
          {
            columnField: "course",
            operatorValue: "equals",
            value: courseId,
          },
        ],
      },
    });

    useEffect(() => {
      fetchTags.refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchTags.refetch]);

    const title = `เพิ่มคำถาม`;
    const allQuestions = fetchAllQuestions.data?.questions?.questions;
    const questions = useMemo(() => {
      return (
        allQuestions?.filter((question) => question.type === "Grid-in") || []
      );
    }, [allQuestions]);

    const getTitle = useCallback(
      (node, parentNode, lowerSiblingCounts, path) => {
        if (path.length === 4 || path.length === 0) {
          return node.title;
        }
        if (path.length === 1) {
          return `line ${node.children.length + 1}`;
        }
        if (path.length === 2) {
          if (node.children.length === 0) {
            return "Main Tag";
          }

          return `Sub Tag ${node.children.length}`;
        }
      },
      []
    );

    const paragraphOptions = useMemo(() => {
      return fetchParagraphs.data?.paragraphs?.map((paragraph) => ({
        label: paragraph.name,
        value: paragraph.id,
      }));
    }, [fetchParagraphs.data]);

    const tagsOptions = useMemo(() => {
      const options = [];
      const tagsData = fetchTags.data?.tags?.configs[0]?.children ?? [];
      const convertTagsDataToOptions = (
        data,
        deforeCode = [],
        deforeTitle = ""
      ) => {
        data.forEach((item) => {
          const newTitle = deforeTitle
            ? `${deforeTitle} > ${item.title}`
            : ` ${item.title}`;
          if (item.children.length > 0) {
            convertTagsDataToOptions(
              item.children,
              [...deforeCode, item.code],
              newTitle
            );
          } else {
            const newArray = [...deforeCode];
            const first = newArray.shift();

            if (first) {
              options.push({
                label: `${
                  first +
                  (item.difficultyLevel
                    ? item.difficultyLevel.toString().padStart(2, "0")
                    : "") +
                  newArray.join("") +
                  item.code
                } - ${newTitle}`,
                value: item.id,
              });
            } else {
              options.push({
                label: `${
                  item.code +
                  (item.difficultyLevel
                    ? item.difficultyLevel.toString().padStart(2, "0")
                    : "") +
                  newArray.join("")
                } - ${newTitle}`,
                value: item.id,
              });
            }
            return null;
          }
        });
      };
      convertTagsDataToOptions(tagsData, []);
      return options;
    }, [fetchTags.data]);

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.questionsPath(courseId), label: "คำถาม" },
        { path: null, label: title },
      ];
    }, [title]);

    const typeOptions = useMemo(
      () => [
        { label: "MCQs", value: "mcqs" },
        { label: "Grid-in", value: "Grid-in" },
      ],
      []
    );

    const categoryOptions = useMemo(() => {
      let categoryList = [];
      allQuestions?.forEach((question) => {
        if (question.category) {
          categoryList = categoryList.concat(question.category);
        }
      });
      categoryList = uniq(categoryList);

      return lodashMap(categoryList, (item) => ({
        label: item,
        value: item,
      }));
    }, [allQuestions]);

    const prefillOptions = useMemo(() => {
      return lodashMap(allQuestions, (item) => ({
        label: item.name,
        value: item.id,
      }));
    }, [allQuestions]);

    useHandleSubmit(
      async (values) => {
        Backdrop.open();
        let newValues = cloneDeep(values);
        try {
          if (newValues.type === "mcqs") {
            const answer = find(
              newValues.answerConfig?.mcqs?.choices ?? [],
              (choice) => choice?.isCorrect === "true"
            );
            if (answer) {
              newValues.answerConfig.mcqs = {
                ...newValues.answerConfig.mcqs,
                answer: answer.value,
              };
            }
          }
          newValues.course = courseId;
          await createQuestion({ variables: newValues });
          paths.questionsPath(courseId).push();
          Notification.success("บันทึกข้อมูลสำเร็จ");
          Backdrop.close();
        } catch (e) {
          notifyError(e.message);
          Backdrop.close();
        }
      },
      [createQuestion]
    );

    const submit = useCallback(async () => {
      let newValues = cloneDeep(values);
      try {
        if (newValues.type === "mcqs") {
          const answer = find(
            newValues.answerConfig?.mcqs?.choices ?? [],
            (choice) => choice?.isCorrect === "true"
          );
          if (answer) {
            newValues.answerConfig.mcqs = {
              ...newValues.answerConfig.mcqs,
              answer: answer.value,
            };
          }
        }
        newValues.course = courseId;
        await createQuestion({ variables: newValues });
        paths.questionsPath(courseId).push();
        Notification.success("บันทึกข้อมูลสำเร็จ");
      } catch (e) {
        notifyError(e.message);
      }
    }, [values, createQuestion]);

    const treeData = useMemo(() => values.tagInfo.list, [values.tagInfo.list]);

    const onTreeChange = useCallback(
      (treeData) => {
        const newTreeData = map({
          treeData,
          getNodeKey: ({ treeIndex }) => treeIndex,
          callback: ({ path, node, parentNode, lowerSiblingCounts }) => {
            if (path.length === 2) {
              node.title = `line ${
                parentNode.children.length -
                lowerSiblingCounts[lowerSiblingCounts.length - 1]
              }`;
            }
            if (path.length === 3) {
              if (parentNode.children.length - 1 === lowerSiblingCounts[2]) {
                node.title = "Main Tag";
              } else {
                node.title = `Sub Tag ${
                  parentNode.children.length -
                  lowerSiblingCounts[lowerSiblingCounts.length - 1] -
                  1
                }`;
              }
            }

            return node;
          },
        });
        setFieldValue("tagInfo.list", newTreeData);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setFieldValue]
    );

    const addNode = useCallback(
      (node, parentNode, lowerSiblingCounts, path) => () => {
        const title = getTitle(node, parentNode, lowerSiblingCounts, path);
        const newTreedata = addNodeUnderParent({
          treeData: treeData,
          parentKey: path[path.length - 1],
          expandParent: true,
          minimumTreeIndex: treeData.length,
          newNode: { title: title, children: [] },
          getNodeKey: ({ treeIndex }) => treeIndex,
        }).treeData;
        setFieldValue("tagInfo.list", newTreedata);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [treeData, setFieldValue]
    );

    const removeNode = useCallback(
      (path) => () => {
        const newTreedata = removeNodeAtPath({
          treeData: treeData,
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
        });
        setFieldValue("tagInfo.list", newTreedata);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [treeData, setFieldValue]
    );

    const editText = useCallback(
      (path, node, fieldName) => (e, value) => {
        const newTreedata = changeNodeAtPath({
          treeData: treeData,
          path,
          newNode: { ...node, [fieldName]: value?.value ?? "" },
          getNodeKey: ({ treeIndex }) => treeIndex,
        });
        setFieldValue("tagInfo.list", newTreedata);
      },
      [treeData, setFieldValue]
    );

    const getOptionLabel = useCallback(
      (option) => {
        const customOption = typeof option === "object" ? option.value : option;

        const matchOption = find(tagsOptions, {
          value: customOption,
        });
        const value = matchOption?.label ?? "";

        return value ?? option.label;
      },
      [tagsOptions]
    );

    const labelListOptions = useMemo(() => {
      const labelList = [];
      questions?.forEach((question) => {
        if (question.answerConfig?.gridIn?.labelList) {
          labelList.push({
            label: question.answerConfig.gridIn.labelList.join(" "),
            value: question.answerConfig.gridIn.labelList.join(" "),
          });
        }
      });

      return uniqBy(labelList, "value");
    }, [questions]);

    const selectLabelList = useCallback(
      (e, value, reason, details) => {
        if (reason === "select-option") {
          value.splice(-1);
          setFieldValue(
            "answerConfig.gridIn.labelList",
            uniq([...value, ...details.option.split(" ")])
          );
          return;
        }
        if (reason !== "blur") {
          setFieldValue("answerConfig.gridIn.labelList", value);
        }
      },
      [setFieldValue]
    );

    const prefillTags = useCallback(() => {
      const question = find(allQuestions, { id: values.prefillTags });
      if (question) {
        setFieldValue("tagInfo", question.tagInfo);
      }
    }, [setFieldValue, values.prefillTags, allQuestions]);

    const initialized = !fetchTags.loading;

    const updatePublised = useCallback(
      (e, value) => {
        setFieldValue("published", value);
      },
      [setFieldValue]
    );

    return {
      courseId,
      title,
      breadcrumbs,
      typeOptions,
      values,
      tagsOptions,
      treeData,
      addNode,
      removeNode,
      onTreeChange,
      editText,
      getTitle,
      getOptionLabel,
      initialized,
      categoryOptions,
      labelListOptions,
      selectLabelList,
      updatePublised,
      isNewPage: true,
      canEdit: true,
      paragraphOptions,
      submit,
      prefillTags,
      prefillOptions,
    };
  })
);

export default enhancer(QuestionNewPage);

const QuestionButton = ({ questionId, questionName }) => {
  return (
    <Button mr={1} onClick={() => paths.questionEditPath(questionId).push()}>
      {questionName}
    </Button>
  );
};
