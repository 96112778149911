import { compose, withHooks } from "enhancers";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import SortableTree from "react-sortable-tree";

const CustomSortableTree = (props) =>
  props.initialized && (
    <DndProvider backend={HTML5Backend}>
      <SortableTree {...props} />
    </DndProvider>
  );

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useState, useEffect } = hooks;
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
      let time = 0;
      const htmlBackendChecker = setInterval(() => {
        if (!window.__isReactDndBackendSetUp) {
          clearInterval(htmlBackendChecker);
          setInitialized(true);
        }
        time += 100;
        if (time > 5000) {
          clearInterval(htmlBackendChecker);
          console.error("htmlBackendChecker timeout 5000ms.");
        }
      }, 100);
    }, []);

    return {
      ...props,
      initialized,
    };
  })
);

export default enhancer(CustomSortableTree);
