import React, { useEffect } from "react";
import { compose, withHooks } from "enhancers";
import { gql, useQuery } from "@apollo/client";
import paths from "routes/paths";

import appStore from "stores/appStore";

import { Redirect, Route, Switch } from "components";
import { BlankLayout, MenuLayout } from "layouts";

import SignInPage from "pages/auth/SignIn";
import SignUpPage from "pages/auth/SignUp";
import SetupPasswordPage from "pages/auth/SetupPassword";
import ResetPasswordPage from "pages/auth/ResetPassword";

import AdminIndexPage from "pages/main/admins/index";
import AdminNewPage from "pages/main/admins/new";
import AdminEditPage from "pages/main/admins/edit";

import CourseIndexPage from "pages/main/course/index";

import ProfilePage from "pages/main/profile";
import Tag from "pages/main/tag";

import ParagraphIndexPage from "pages/main/paragraph";
import ParagraphNewPage from "pages/main/paragraph/new";
import ParagraphEditPage from "pages/main/paragraph/edit";

import QuestionIndexPage from "pages/main/question";
import QuestionNewPage from "pages/main/question/new";
import QuestionEditPage from "pages/main/question/edit";
import QuestionGeneratePage from "pages/main/question/ai-gen";

import ExamIndexPage from "pages/main/exam/index";
import ExamNewPage from "pages/main/exam/new";

// prettier-ignore
const InitialPages = () => (
  <BlankLayout>
    <Switch>
      <Route path={paths.signUpPath()} exact component={SignUpPage} />
      <Route path={paths.setupPasswordPath()} exact component={SetupPasswordPage} />

      <Redirect to={paths.signUpPath()} />
    </Switch>
  </BlankLayout>
);

// prettier-ignore
const GuestPages = () => (
  <BlankLayout>
    <Switch>
      <Route path={paths.signInPath()} exact component={SignInPage} />
      <Route path={paths.setupPasswordPath()} exact component={SetupPasswordPage} />
      <Route path={paths.resetPasswordPath()} exact component={ResetPasswordPage} />

      <Redirect to={paths.signInPath()} />
    </Switch>
  </BlankLayout>
);

// prettier-ignore
const MainPages = () => (
  <Switch>
    <Route path={paths.adminsPath()} exact layout={MenuLayout} component={AdminIndexPage} />
    <Route path={paths.adminNewPath()} exact layout={MenuLayout} component={AdminNewPage} />
    <Route path={paths.adminEditPath(":id")} exact layout={MenuLayout} component={AdminEditPage} />

    <Route path={paths.coursesPath()} exact layout={MenuLayout} component={CourseIndexPage} />

    <Route path={paths.paragraphsPath(":courseId")} exact layout={MenuLayout} component={ParagraphIndexPage} />
    <Route path={paths.paragraphNewPath(":courseId")} exact layout={MenuLayout} component={ParagraphNewPage} />
    <Route path={paths.paragraphEditPath(":courseId", ":id")} exact layout={MenuLayout} component={ParagraphEditPage} />

    <Route path={paths.questionsPath(":courseId")} exact layout={MenuLayout} component={QuestionIndexPage} />
    <Route path={paths.questionNewPath(":courseId")} exact layout={MenuLayout} component={QuestionNewPage} />
    <Route path={paths.questionEditPath(":courseId", ":id")} exact layout={MenuLayout} component={QuestionEditPage} />
    <Route path={paths.questionAIGeneratePath(":courseId", ":id", ":amount")} exact layout={MenuLayout}
           component={QuestionGeneratePage} />

    <Route path={paths.tagsPath(":courseId")} exact layout={MenuLayout} component={Tag} />

    <Route path={paths.examsPath(":courseId")} exact layout={MenuLayout} component={ExamIndexPage} />
    <Route path={paths.examNewPath(":courseId")} exact layout={MenuLayout} component={ExamNewPage} />

    <Route path={paths.profilePath()} exact layout={MenuLayout} component={ProfilePage} />

    <Redirect to={paths.homePath()} />
  </Switch>
);

interface RoutesProps {
  initialized: boolean;
  hasFirstAdmin: boolean;
  isAuthorized: boolean;
}

// prettier-ignore
const Routes = (props: RoutesProps) => {
  if (!props.initialized) {
    return null;
  } else if (!props.hasFirstAdmin) {
    return <InitialPages />;
  } else if (!props.isAuthorized) {
    return <GuestPages />;
  } else {
    return <MainPages />;
  }
};

export const API = {
  GET_APP_INFO: gql`
    query GET_APP_INFO {
      info {
        hasFirstAdmin
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      currentUser {
        email
        firstName
        lastName
      }
    }
  `,
};

const enhancer = compose(
  withHooks(() => {
    const appInfo = useQuery(API.GET_APP_INFO);
    const currentUser = useQuery(API.GET_CURRENT_USER, {
      onCompleted: (data) => {
        appStore.setCurrentUser(data.currentUser);
      },
    });

    const initialized = !appInfo.loading && !currentUser.loading;
    const isAuthorized = !currentUser.error;
    const hasFirstAdmin = !!appInfo.data?.info?.hasFirstAdmin;

    useEffect(() => {
      const $splashScreen = document.getElementById("splash-screen");
      if ($splashScreen) {
        const display = initialized ? "none" : "";
        $splashScreen.style.display = display;
      }
    }, [initialized]);

    return {
      initialized,
      isAuthorized,
      hasFirstAdmin,
    };
  })
);

export default enhancer(Routes);
