import { Course } from "api/graphql";
import { BaseStore } from "stores";

class CourseStore extends BaseStore {
  constructor() {
    super("courseStore", {
      currentCourse: null,
    });
  }

  setCurrentCourse(course: Course) {
    this.setState({ currentCourse: course });
  }
}

export default new CourseStore();
