import { Card, CardContent, Typography } from "@material-ui/core";
import { compose, withHooks } from "enhancers";

const SimpleCard = ({ title, content }) => (
  <Card
    variant="outlined"
    style={{ margin: "16px", display: "flex", width: "100%" }}
  >
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h5" component="h2">
        {content}
      </Typography>
    </CardContent>
  </Card>
);

const enhancer = compose(
  withHooks((props) => {
    return {
      title: props.title,
      content: props.content,
    };
  })
);

const EnhancedCard = enhancer(SimpleCard);

export default EnhancedCard;
