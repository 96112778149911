import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  Button as MuiButton,
  CardMedia as MuiCardMedia,
} from "@material-ui/core";
import { compose, withHooks } from "enhancers";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

const Button = styled(MuiButton)(spacing);
const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const MediaCard = ({ title, content, image, cardAction, onClick }) => (
  <Card mb={6} onClick={onClick}>
    <CardActionArea>
      <CardMedia image={image} title={title} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        {content && (
          <Typography variant="body2" color="textSecondary" component="p">
            {content}
          </Typography>
        )}
      </CardContent>
    </CardActionArea>
    {cardAction && (
      <CardActions>
        <Button size="small" color="primary">
          {cardAction.label}
        </Button>
      </CardActions>
    )}
  </Card>
);

const enhancer = compose(
  withHooks((props) => {
    return {
      title: props.title,
      content: props.content,
      image: props.image || "/static/img/unsplash/unsplash-1.jpg",
      cardAction: props.cardAction,
      onClick: props.onClick,
    };
  })
);

const EnhancedCard = enhancer(MediaCard);

export default EnhancedCard;
