import axios, { Axios } from "axios";
import humps from "humps";
import Qs from "qs";
import objectToFormData from "object-to-formdata";
import downloadjs from "downloadjs";
import mime from "mime-types";
import ENV from "env";
import { get, set, compact } from "lodash";
import { formatISO } from "date-fns";
import { getToken, safe } from "common/helper";
import { Notification } from "components";
// import paths from "routes/paths";

export class ApiValidationError extends Error {
  constructor(data) {
    super("");
    this.name = "ApiValidationError";
    this.data = data;
  }
}

export class ApiError extends Error {
  constructor(message) {
    super("");
    this.name = "ApiError";
    this.message = message;
  }
}

const arraybufferToJson = (arraybuffer) => {
  const decodedString = String.fromCharCode.apply(
    null,
    new Uint8Array(arraybuffer)
  );
  return JSON.parse(decodedString);
};

export const transformKeysToSnakeCase = (object) => {
  if (object instanceof File) {
    return object;
  }
  if (object instanceof Array) {
    return object.map((obj) => transformKeysToSnakeCase(obj));
  }
  if (object instanceof Object) {
    return Object.entries(object)
      .map(([key, value]) => [
        humps.decamelize(key),
        transformKeysToSnakeCase(value),
      ])
      .reduce(
        (memo, [key, value]) => ({
          ...memo,
          [key]: transformKeysToSnakeCase(value),
        }),
        {}
      );
  }
  return object;
};

const transformKeysToCamelCase = (object) => {
  if (object instanceof ArrayBuffer) {
    return object;
  }
  return humps.camelizeKeys(object);
};

const isFileExist = (object) => {
  if (object instanceof File) {
    return true;
  }
  if (object instanceof Array) {
    return object
      .map((obj) => isFileExist(obj))
      .reduce((memo, exist) => memo || exist, false);
  }
  if (object instanceof Object) {
    return isFileExist(Object.values(object));
  }
  return false;
};

const transformFormDataIfFileExist = (object) =>
  isFileExist(object) ? objectToFormData(object) : object;

const transformStringToDate = (object) => {
  if (object instanceof ArrayBuffer) {
    return object;
  }
  if (object instanceof Array) {
    return object.map((obj) => transformStringToDate(obj));
  }
  if (object instanceof Object) {
    return Object.entries(object).reduce(
      (memo, [key, value]) => ({
        ...memo,
        [key]: transformStringToDate(value),
      }),
      {}
    );
  }
  if (typeof object === "string") {
    if (/^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?/.test(object)) {
      return new Date(object);
    }
  }
  return object;
};

const transformDateToString = (object) => {
  if (object instanceof File) {
    return object;
  }
  if (object instanceof Date) {
    return formatISO(object);
  }
  if (object instanceof Array) {
    return object.map((obj) => transformDateToString(obj));
  }
  if (object instanceof Object) {
    return Object.entries(object).reduce(
      (memo, [key, value]) => ({
        ...memo,
        [key]: transformDateToString(value),
      }),
      {}
    );
  }
  return object;
};
class Api extends Axios {
  constructor(config = {}, ...rest) {
    super(
      {
        baseURL: config.baseURL,
        timeout: 3000000,
        validateStatus: (status) => status >= 200 && status < 400,
        transformRequest: compact([
          transformDateToString,
          transformKeysToSnakeCase,
          transformFormDataIfFileExist,
          ...axios.defaults.transformRequest,
          config.transformRequest,
        ]),
        transformResponse: compact([
          ...axios.defaults.transformResponse,
          transformKeysToCamelCase,
          transformStringToDate,
          config.transformResponse,
        ]),
      },
      ...rest
    );

    this.interceptors.request.use((request) => {
      if (config.requestInterceptor) {
        config.requestInterceptor(request);
      }

      if (request.method === "get" || request.method === "delete") {
        request.paramsSerializer = (params) => {
          return Qs.stringify(params, {
            arrayFormat: "brackets",
            encode: true,
            strictNullHandling: true,
          });
        };

        request.params = [
          transformDateToString,
          transformKeysToSnakeCase,
          transformFormDataIfFileExist,
        ].reduce((memo, transform) => transform(memo), request.params);
      }

      return request;
    });

    this.interceptors.response.use(
      (response) => {
        if (config.responseInterceptor) {
          config.responseInterceptor(response);
        }
        return response;
      },
      (error) => {
        const notify = true;

        let response = get(error, "response.data", {});
        if (response instanceof ArrayBuffer) {
          response = arraybufferToJson(response);
        }
        const { code, message, errors = [] } = response;
        const FALLBACK_ERROR_MESSAGE = "Something went wrong!!!";

        switch (code) {
          case "unauthorized":
            // const authStore = require("@stores/authStore").default;
            // authStore.signOut();
            break;
          case "validate_failed":
            const validateData = errors.reduce((memo, { field, message }) => {
              field = humps.camelize(field);
              field = field.replace(/\//g, ".");

              message = message || "invalid";

              set(memo, field, message);
              return memo;
            }, {});

            validateData._error =
              message ||
              errors.map(({ message }) => message).join(", ") ||
              FALLBACK_ERROR_MESSAGE;

            if (notify) {
              Notification.error(validateData._error);
            }

            throw new ApiValidationError(validateData);
          case "forbidden":
            window.location.href = "/";
            return;
          default:
            const errorMessage = message || FALLBACK_ERROR_MESSAGE;

            if (notify) {
              Notification.error(errorMessage);
            }

            throw new ApiError(errorMessage);
        }

        return Promise.reject(error);
      }
    );
  }

  async get(path, params, options = {}) {
    const response = await super.get(path, { params, ...options });
    return response;
  }

  async delete(path, params, options = {}) {
    const response = await super.delete(path, { params, ...options });
    return response;
  }

  async download(path, params, options = {}) {
    let {
      method = "get",
      viewMode = false,
      timeout = 3000000,
      customFileName,
      ...restOptions
    } = options;
    const response = await this[method](
      path,
      {
        // pure: true,
        ...params,
      },
      { responseType: "arraybuffer", timeout, ...restOptions }
    );
    const { data, headers } = response;

    const filename = safe(() => {
      try {
        // NOTE: Many filename from server fix follow server sent.
        return decodeURIComponent(
          headers["content-disposition"].match(/filename\*="?(.*)"?/)[1]
        );
      } catch {
        return headers["content-disposition"].match(/filename="?(.*)"?/)[1];
      }
    });
    // NOTE: Cannot rely "content-type" from server
    // const mimeType = headers["content-type"]
    const mimeType = mime.lookup(filename ?? customFileName);

    if (viewMode) {
      // NOTE: when need prevent by browser suggest to use `https://github.com/lancedikson/bowser`
      const file = new Blob([data], { type: mimeType });
      const fileURL = URL.createObjectURL(file);

      // Check popup blocker
      let win = window.open(fileURL, "_blank");
      let loading = setTimeout(function () {
        //Browser has blocked it
        alert(
          "ไม่สามารถเปิดดูไฟล์ได้ หากติดตั้ง blocker extension ไว้กรุณาปิดใช้งาน และลองใหม่อีกครั้ง"
        );
      }, 5000);

      try {
        win.addEventListener("load", function () {
          clearTimeout(loading);
        });
      } catch (e) {
        clearTimeout(loading);
        alert(
          "ไม่สามารถเปิดดูไฟล์ได้ หากติดตั้ง blocker extension ไว้กรุณาปิดใช้งาน และลองใหม่อีกครั้ง"
        );
      }
    } else {
      downloadjs(data, filename ?? customFileName, mimeType);
    }
  }
}

const server = new Api({
  baseURL: ENV.REST_API_ENDPOINT,
  transformRequest: (data, headers) => {
    const authToken = getToken();
    // const currentCompanyId = paths.getCurrentCompanyId();
    const fcmToken = window.FIREBASE_TOKEN;

    if (authToken) {
      headers["Authorization"] = authToken;
    }

    // if (currentCompanyId) {
    //   headers["Company-Id"] = currentCompanyId;
    // }

    if (fcmToken) {
      headers["FCM-Token"] = fcmToken;
    }

    return data;
  },
});

export const downloadStaticFile = async (name) => {
  window.location.href = `${ENV.API_HOST}/${name}`;
};

export default server;
