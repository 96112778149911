import React from "react";
import { compose, defaultProps, withHooks } from "enhancers";
import {
  IconButton,
  Box,
  Chip,
  Typography,
  DownloadFileS3Link,
  BrowseButton,
  DynamicMenu,
} from "components";
import { formatPhoneNumber, toCurrency } from "utils/helper";
import { map, find, isEmpty } from "lodash";
import { format, parseISO } from "date-fns";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";

import { Popper, TextField, withStyles } from "@material-ui/core";

import { ReactComponent as FacebookIcon } from "assets/icon/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/icon/instagram.svg";
import { ReactComponent as LineIcon } from "assets/icon/line.svg";
import { ReactComponent as EmailIcon } from "assets/icon/email.svg";

import { ReactComponent as GreenDot } from "assets/icon/green_dot.svg";
import { ReactComponent as RedDot } from "assets/icon/red_dot.svg";

import { ReactComponent as WarningAmber } from "assets/icon/warning_amber.svg";
import { Autocomplete } from "@material-ui/lab";
import { isEqual } from "lodash";

const StyledDataGrid = withStyles({
  root: {
    // "& .MuiDataGrid-viewport": {
    //   maxHeight: "none !important",
    // },
    // "& .MuiDataGrid-renderingZone": {
    //   maxHeight: "none !important",
    // },
    // "& .MuiDataGrid-cell": {
    //   lineHeight: "unset !important",
    //   maxHeight: "none !important",
    //   whiteSpace: "normal",
    // },
    // "& .MuiDataGrid-row": {
    //   maxHeight: "none !important",
    // },
  },
})(DataGrid);

export const RENDER_CELLS = {
  text(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return (
      <Box
        fullWidth
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {value}
      </Box>
    );
  },
  caption(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return (
      <Typography
        fullWidth
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        variant="caption"
      >
        {value}
      </Typography>
    );
  },
  phoneNumber(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return formatPhoneNumber(value);
  },
  date(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return value;
  },
  dateTime(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return format(parseISO(value), "dd/MM/yyyy, HH:mm");
  },
  currency(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return (
      <Box marginLeft="auto">
        {toCurrency(value, { minimumFractionDigits: 0 })}
      </Box>
    );
  },
  social(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }

    const [info, channel] = value.split("{{STUDATA_SPLIT_STRING}}");

    if (!info && !channel) {
      return <></>;
    }

    const Icon = {
      facebook: FacebookIcon,
      ig: InstagramIcon,
      line: LineIcon,
      email: EmailIcon,
    }[channel];

    return (
      <Box display="flex" alignItems="center">
        {Icon && <Icon style={{ marginRight: 8 }} />}
        {info}
      </Box>
    );
  },
  selector(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return find(row.colDef.options, { value })?.label;
  },
  tags(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    const values = value.split("~");
    return (
      <Box whiteSpace="pre-wrap" p={1} pt={0}>
        {map(values, (v) => {
          return (
            <Chip
              size="small"
              label={v}
              mr={1}
              mt={1}
              style={{ fontSize: 12, lineHeight: 14 }}
            />
          );
        })}{" "}
      </Box>
    );
  },
  notiStatus(row) {
    const { value } = row;
    if (!value && value !== false) {
      return <></>;
    }

    return (
      <Box display="flex" alignItems="center" mx="auto">
        {value ? <GreenDot /> : <RedDot />}
      </Box>
    );
  },
  boolean(row) {
    const { value } = row;
    if (!value && value !== false) {
      return <></>;
    }

    return (
      <Box display="flex" alignItems="center" mx="auto">
        {value ? <GreenDot /> : <RedDot />}
      </Box>
    );
  },
  warningStatus(row) {
    const { value } = row;
    if (!value && value !== false) {
      return <></>;
    }

    return (
      <Box display="flex" alignItems="center" mx="auto">
        {value ? <WarningAmber /> : null}
      </Box>
    );
  },
  actions(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }

    return value.map((action, index) => {
      const { Icon, onClick, can } = action;
      if (can === false) {
        return <></>;
      }

      return (
        <IconButton
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            onClick(row);
          }}
        >
          <Icon />
        </IconButton>
      );
    });
  },
  upload(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }

    return value.map((action, index) => {
      return (
        <BrowseButton
          key={index}
          ml={4}
          {...action}
          onBrowse={(file) => {
            action.onBrowse(row, file);
          }}
        />
      );
    });
  },
  downloadLink(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }

    return <DownloadFileS3Link filename={value} url={value} />;
  },
  menus(row) {
    const { value } = row;

    if (!value || isEmpty(value)) {
      return <></>;
    }

    return <DynamicMenu options={value} row={row} />;
  },
};

const customValue = {
  date(row) {
    const { value } = row;
    if (!value) {
      return null;
    }
    return format(parseISO(value), "dd/MM/yyyy, HH:mm");
  },
  social(row) {
    const { value } = row;

    if (!value.channel || !value.info) {
      return null;
    }

    const { channel, info } = value;

    return `${info}{{STUDATA_SPLIT_STRING}}${channel}`;
  },
  tags(row) {
    const { value } = row;
    if (!value) {
      return null;
    }

    const findLastTags = (array, result) => {
      array.forEach((item) => {
        if (item.children.length !== 0) {
          findLastTags(item.children, result);
        } else {
          result.push(item.title);
        }
      });
      return result;
    };
    const result = findLastTags(value.list, []);

    const labels = map(result, (item) => {
      const option = find(
        row.row.tagsOptions,
        (tagsOption) => tagsOption.value === item
      );
      return option?.label;
    }).join("~");

    return labels;
  },
};

//https://github.com/mui-org/material-ui-x/blob/HEAD/packages/grid/_modules_/grid/constants/localeTextConstants.ts
//https://material-ui.com/components/data-grid/localization/
// components={{
//   Toolbar: GridToolbar,
// }}

const enhancer = compose(
  defaultProps({
    style: {
      minHeight: 606,
    },
    density: "compact",
    autoHeight: true,
    autoPageSize: true,
    disableSelectionOnClick: true,
    rowsPerPageOptions: [25, 50, 100],
  }),
  withHooks((props, hooks) => {
    const {
      columns,
      onRowClickTo,
      onRowClick,
      autoDetectInitialFilterOnUrlQueryParams,
      defaultPageSize,
      initialFilter,
      paginationMode,
      refetch,
      filterMode,
      sortingMode,
      ...rest
    } = props;
    const { useMemo, useCallback, useState, useUrlParam, useEffect } = hooks;
    const [pageSize, setPageSize] = useState(defaultPageSize ?? 25);
    const [page, onPageChange] = useState(0);
    const [sortModel, setSortModel] = useState([]);

    useEffect(() => {
      if (paginationMode === "server") {
        refetch({ page: page, pageSize: pageSize });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize, paginationMode]);

    const customColumns = useMemo(() => {
      return map(columns, ({ type = "text", customFilterType, ...rest }) => {
        const filterOptions = [];

        if (customFilterType === "selector") {
          filterOptions.push({
            label: "contains",
            value: "contains",
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.operatorValue
              ) {
                return null;
              }

              return (params) => {
                return params.value.includes(filterItem?.value?.value);
              };
            },
            InputComponent: ({ item, applyValue }) => (
              <Autocomplete
                id="value filter"
                options={rest.valueOptions}
                getOptionLabel={(option) => option.label ?? ""}
                value={item.value ?? ""}
                onChange={(e, value) => {
                  applyValue({ ...item, value: value });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Value" variant="standard" />
                )}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{ width: "500px" }}
                    placement="bottom-start"
                  />
                )}
              />
            ),
          });
        }

        const result = {
          renderCell:
            type === "text" ? undefined : RENDER_CELLS[type] || undefined,
          valueGetter: customValue[type] || undefined,
          type: ["singleSelect", "dateTime", "boolean"].includes(type)
            ? type
            : undefined,
          ...rest,
        };

        return filterOptions.length === 0
          ? result
          : { ...result, filterOperators: filterOptions };
      });
    }, [columns]);

    const customOnRowClick = useCallback(
      (row) => {
        if (onRowClickTo) {
          return onRowClickTo(row.id).newTab();
        }
        if (onRowClick) {
          return onRowClick(row);
        }
      },
      [onRowClickTo, onRowClick]
    );

    const onPageSizeChange = useCallback((newPage) => setPageSize(newPage), []);

    const urlParams = useUrlParam();
    const initialFilterModel = useMemo(() => {
      const items = map(urlParams, (value, key) => {
        return {
          columnField: key,
          operatorValue: "contains",
          value: value,
        };
      });

      return isEmpty(items) ? undefined : { items };
    }, [urlParams]);

    const [filterModel, onFilterModelChange] = useState(
      initialFilter ?? initialFilterModel
    );

    const customOnFilterModelChange = useCallback(
      (items) => {
        if (items.items.length === 0 && initialFilter) {
          onFilterModelChange(initialFilter);
          refetch({ filters: [] });
          return;
        }

        if (filterMode === "server" && items.items[0].value) {
          refetch({
            filters: items.items,
            sorts: sortModel,
          });
        }
        onFilterModelChange(items);
      },
      [initialFilter, filterMode, refetch, sortModel]
    );

    const handleSortModelChange = useCallback(
      (newModel) => {
        if (!isEqual(newModel, sortModel) && sortingMode === "server") {
          refetch({ filters: filterModel.items, sorts: newModel });
        }
        setSortModel(newModel);
      },
      [filterModel, refetch, sortModel, sortingMode]
    );
    return {
      filterMode,
      pageSize,
      onPageSizeChange,
      page,
      onPageChange,
      paginationMode,
      ...rest,
      columns: customColumns,
      onRowClick: customOnRowClick,
      filterModel,
      onFilterModelChange: customOnFilterModelChange,
      onSortModelChange: handleSortModelChange,
      sortModel,
      components: {
        Toolbar: props.includeToolbar ? GridToolbar : undefined,
      },
    };
  })
);

const CustomTable = enhancer(StyledDataGrid);

CustomTable.RENDER_CELLS = RENDER_CELLS;

export default CustomTable;
