import { compose, withFormik, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Backdrop, Box, Button, Form, Table } from "components";
import { gql, notifyError, notifySuccess, paths } from "utils/helper";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

const columns = [
  { width: 400, field: "name", headerName: "ชื่อคำถาม" },
  { width: 150, field: "type", headerName: "ประเภท" },
  { width: 150, field: "category", headerName: "หมวดหมู่" },
];

export const ExamNewPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Form>
      <Box display="flex" flexDirection="column">
        <Table
          columns={columns}
          rows={props.tableData}
          loading={props.loading}
          density="compact"
          autoHeight
          disableSelectionOnClick
          includeToolbar
          checkboxSelection
          onSelectionModelChange={props.onSelectionChange}
        />
        {/* <Divider my={6} />
        <Field
          name="pdfInfo.header"
          component={TextField}
          label="Header"
          mt={6}
        />
        <Field
          name="pdfInfo.footer"
          component={TextField}
          label="Footer"
          mt={6}
        />
        <Field
          name="pdfInfo.marginTop"
          component={TextField}
          label="ระยะขอบด้านบน (px)"
          mt={6}
          type="number"
        />
        <Field
          name="pdfInfo.marginRight"
          component={TextField}
          label="ระยะขอบด้านขวา (px)"
          mt={6}
          type="number"
        />
        <Field
          name="pdfInfo.marginBottom"
          component={TextField}
          label="ระยะขอบด้านล่าง (px)"
          mt={6}
          type="number"
        />
        <Field
          name="pdfInfo.marginLeft"
          component={TextField}
          label="ระยะขอบด้านซ้าย (px)"
          mt={6}
          type="number"
        />
        <Field
          name="pdfInfo.questionSpacing"
          component={TextField}
          label="ความห่างด้านบนคำถามแต่ละข้อ (px)"
          mt={6}
          type="number"
        />
        <Field
          name="pdfInfo.questionPaddingSpacing"
          component={TextField}
          label="ความห่างด้านล่างคำถามแต่ละข้อ (px)"
          mt={6}
          type="number"
        />
        <Field
          name="pdfInfo.choiceSpacing"
          component={TextField}
          label="ความห่างของตัวเลือก (px)"
          mt={6}
          type="number"
        /> */}
        <Button type="submit" color="primary" width={74} p={0} mt={10}>
          บันทึก
        </Button>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  CREATE_EXAM: gql`
    mutation CREATE_EXAM(
      $transactionId: String
      $studentEnrollId: String
      $questionIds: [String!]
      $course: String!
    ) {
      createExam(
        input: {
          transactionId: $transactionId
          studentEnrollId: $studentEnrollId
          questionIds: $questionIds
          course: $course
        }
      ) {
        id
      }
    }
  `,
  FETCH_QUESTIONS: gql`
    query FETCH_QUESTIONS(
      $page: Float
      $pageSize: Float
      $filters: JSON
      $sorts: JSON
    ) {
      questions(
        input: { filters: $filters, sorts: $sorts }
        paginate: { page: $page, pageSize: $pageSize }
      ) {
        questions {
          id
          name
          type
          category
        }
      }
    }
  `,
};
const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withHooks((props, hooks) => {
    const { values, setFieldValue } = props;
    const {
      useMemo,
      useHandleSubmit,
      useQuery,
      useMutation,
      useEffect,
      useCallback,
      useUrlPath,
    } = hooks;
    const { courseId } = useUrlPath();
    const fetchQuestions = useQuery(API.FETCH_QUESTIONS, {
      variables: {
        filters: [
          {
            columnField: "published",
            operatorValue: "equals",
            value: true,
          },
          {
            columnField: "course",
            operatorValue: "equals",
            value: courseId,
          },
        ],
        sorts: [{ field: "updatedAt", sort: "desc" }],
        page: 0,
        pageSize: 100,
      },
    });
    const [createExam] = useMutation(API.CREATE_EXAM);

    useEffect(() => {
      fetchQuestions.refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchQuestions.refetch]);

    const title = `สร้างข้อสอบ`;

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.examsPath(), label: "ข้อสอบ" },
        { path: null, label: title },
      ];
    }, [title]);

    useHandleSubmit(async (values) => {
      let newValues = cloneDeep(values);
      try {
        await Backdrop.open();
        newValues = {
          ...newValues,
          transactionId: `TID-${new Date().getTime()}`,
          studentEnrollId: uuidv4(),
          course: courseId,
        };
        await createExam({ variables: newValues });
        paths.examsPath(courseId).push();
        Backdrop.close();
        notifySuccess("บันทึกข้อมูลสำเร็จ");
      } catch (e) {
        Backdrop.close();
        notifyError(e.message);
      }
    }, []);

    const questionOptions = useMemo(() => {
      const options = fetchQuestions.data?.questions?.questions.map(
        (question) => ({
          label: question.name,
          value: question.id,
        })
      );
      return options;
    }, [fetchQuestions.data]);

    const tableData = useMemo(() => {
      if (fetchQuestions.loading || fetchQuestions.error) {
        return [];
      }

      return fetchQuestions.data?.questions.questions.map((question) => {
        const { ...rest } = question;
        return {
          ...rest,
        };
      });
    }, [fetchQuestions.loading, fetchQuestions.data, fetchQuestions.error]);

    const onSelectionChange = useCallback(
      (newSelection) => {
        setFieldValue("questionIds", newSelection);
      },
      [setFieldValue]
    );

    return {
      courseId,
      title,
      breadcrumbs,
      values,
      questionOptions,
      tableData,
      useCallback,
      onSelectionChange,
    };
  })
);

export default enhancer(ExamNewPage);
