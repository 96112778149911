import Notification from "components/common/Notification";
import { default as orgOmitDeep } from "omit-deep";
import { cloneDeep, isString } from "lodash";
import { format, getYear, parseISO } from "date-fns";
import server from "common/api";
export { default as deepEqual } from "deep-equal";
export { gql } from "@apollo/client";
export * as Yup from "yup";
export { default as paths } from "routes/paths";
export { sprintf } from "sprintf-js";

export const toDate = (date) => {
  if (isString(date)) {
    date = parseISO(date);
  }
  return date;
};

export const formatDate = (date, pattern) => {
  if (isString(date)) {
    date = parseISO(date);
  }

  if (date) {
    pattern = pattern.replace("be(yyyy)", `${getYear(date) + 543}`);
    return format(date, pattern);
  } else {
    return null;
  }
};

export const deepTransformStringToDate = (object) => {
  if (object instanceof ArrayBuffer) {
    return object;
  }
  if (object instanceof Array) {
    return object.map((obj) => deepTransformStringToDate(obj));
  }
  if (object instanceof Object) {
    return Object.entries(object).reduce(
      (memo, [key, value]) => ({
        ...memo,
        [key]: deepTransformStringToDate(value),
      }),
      {}
    );
  }
  if (typeof object === "string") {
    if (/^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?/.test(object)) {
      return new Date(object);
    }
  }
  return object;
};

export const getFullAddress = (info) => {
  const isBangkok = info.province === "กรุงเทพมหานคร";
  const subDistrictPrefix = isBangkok ? "แขวง" : "ตำบล";
  const districtPrefix = isBangkok ? "เขต" : "อำเภอ";
  return `${info.address ?? ""} ${
    info.subDistrict ? `${subDistrictPrefix} ${info.subDistrict}` : ""
  } ${info.district ? `${districtPrefix} ${info.district}` : ""} ${
    info.province ?? ""
  } ${info.zipCode ?? ""}`
    .replace(/ +/g, " ")
    .replace(/^ *$/, "");
};

export function toCurrency(number, options = {}) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...options,
  }).format(number);
}

export function omitDeep(object, key) {
  return orgOmitDeep(cloneDeep(object), key);
}

export function cleanTypename(object) {
  return omitDeep(object, "__typename");
}

export function forceDownload(href, filename) {
  var anchor = document.createElement("a");
  anchor.href = href;
  anchor.download = filename ?? href;
  document.body.appendChild(anchor);
  anchor.click();
}

export async function downloadFileFromS3(attachmentId, filename) {
  if (!attachmentId) {
    notifyError("Attachment id is null");
    return null;
  }

  const res = await server.get(
    `/api/v1/attachments/${attachmentId}/private_url`
  );
  await server.download(
    res.data.privateUrl,
    {},
    {
      viewMode: true,
      customFileName:
        filename ?? `${formatDate(new Date(), "yyyyMMddhhmmss")}.pdf`,
    }
  );
  return attachmentId;
}
export function notify(message) {
  Notification.notify(message);
}

export function notifySuccess(message) {
  Notification.success(message);
}

export function notifyError(error) {
  if (isString(error)) {
    Notification.error(error);
  } else {
    let errorMessage = null;
    try {
      errorMessage = error?.networkError?.result?.errors
        ?.map((e) => e.message)
        ?.join(", ");
    } catch (e) {}
    errorMessage =
      errorMessage ||
      error?.networkError?.result?.message ||
      "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน";
    Notification.error(errorMessage);
  }
}

export function getErrorMessage(error) {
  if (isString(error)) {
    return error;
  } else {
    let errorMessage = null;
    try {
      errorMessage = error?.networkError?.result?.errors
        ?.map((e) => e.message)
        ?.join(", ");
    } catch (e) {}
    errorMessage =
      errorMessage ||
      error?.networkError?.result?.message ||
      "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน";
    return errorMessage;
  }
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const showSplashScreen = (isShow = true) => {
  const $splashScreen = window.document.getElementById("splash-screen");
  const $appScreen = window.document.getElementById("root");
  if ($appScreen) {
    $appScreen.style.display = isShow ? "none" : "block";
  }
  if ($splashScreen) {
    $splashScreen.style.display = isShow ? "flex" : "none";
  }
};

export const redirect = (pathname) => {
  if (window.location.pathname !== pathname) {
    window.location.href = pathname;
  }
};

export const formatPhoneNumber = (str) => {
  let cleaned = ("" + str).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "" + match[1] + "-" + match[2] + "-" + match[3];
  }
  return null;
};

export const formatFullName = (firstName, lastName) => {
  return `${firstName || ""} ${lastName || ""}`.replace(/^ $/, "");
};

export const formatBankNumber = (str) => {
  let cleaned = ("" + str).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{1})(\d{5})(\d*)$/);
  if (match) {
    return "" + match[1] + "-" + match[2] + "-" + match[3] + "-" + match[4];
  }
  return null;
};

export const formatTaxId = (str) => {
  let cleaned = ("" + str).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})$/);
  if (match) {
    return (
      "" +
      match[1] +
      "-" +
      match[2] +
      "-" +
      match[3] +
      "-" +
      match[4] +
      "-" +
      match[5]
    );
  }
  return null;
};

const ranges = [
  { divider: 1e18, suffix: "E" },
  { divider: 1e15, suffix: "P" },
  { divider: 1e12, suffix: "T" },
  { divider: 1e9, suffix: "G" },
  { divider: 1e6, suffix: "M" },
  { divider: 1e3, suffix: "k" },
];

export const formatNumber = (n) => {
  let isNegativeNumber = false;
  if (n < 0) {
    isNegativeNumber = true;
    n = Math.abs(n);
  }
  for (var i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      if (isNegativeNumber) {
        return (
          "-" + (n / ranges[i].divider).toFixed(2).toString() + ranges[i].suffix
        );
      } else {
        return (n / ranges[i].divider).toFixed(2).toString() + ranges[i].suffix;
      }
    }
  }

  return n.toString();
};
