import React from "react";
import { compose, withHooks } from "enhancers";
import { Box, Field, Hidden, TextField, Typography } from "components";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { ReactComponent as EditIcon } from "assets/icon/edit.svg";
import { VisibilityOutlined } from "@material-ui/icons";

const LatexInput = (props) => (
  <Box {...props}>
    <Box display="flex" alignItems="center">
      {props.label && (
        <Typography variant="h4" mr={2}>
          {props.label}
        </Typography>
      )}
      <Hidden when={props.disabled}>
        {!props.isEdit && (
          <EditIcon
            onClick={props.switchIsEdit}
            style={{ marginRight: "8px", width: "17px", height: "17px" }}
          />
        )}
        {props.isEdit && (
          <VisibilityOutlined
            onClick={props.switchIsEdit}
            mr={2}
            style={{ marginRight: "8px", width: "17px", height: "17px" }}
          />
        )}
      </Hidden>
    </Box>
    <Box mt={2} width="100%">
      {!props.isEdit && (
        <Box
          style={{
            border: "1px solid",
            borderRadius: "8px",
            minHeight: "57px",
            background: "#ccc",
            overflowWrap: "anywhere",
          }}
          p={2}
        >
          <MathJaxContext
            version={3}
            config={{
              loader: {
                load: ["[img]/img.min.js"],
                paths: { img: "https://cdn.jsdelivr.net/npm/mathjax-img@3" },
              },
              tex: {
                inlineMath: [
                  ["$", "$"],
                  ["\\(", "\\)"],
                ],
                packages: { "[+]": ["img"] },
              },
            }}
          >
            <MathJax dynamic={true}>
              <div
                dangerouslySetInnerHTML={{
                  __html: (props.value ?? "")
                    .replaceAll("\\\\", "\\")
                    .replaceAll("\n", "<br/>"),
                }}
              />
            </MathJax>
          </MathJaxContext>
        </Box>
      )}
      {props.isEdit && (
        <Field
          name={props.name}
          component={TextField}
          multiline
          width="100%"
          disabled={props.disabled}
          // onBlur={props.customOnBlur}
        />
      )}
    </Box>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useState, useCallback } = hooks;
    const { field, form, ...rest } = props;
    const [isEdit, setIsEdit] = useState(false);

    const switchIsEdit = useCallback(() => {
      setIsEdit(!isEdit);
    }, [isEdit]);

    // const customOnBlur = () => {
    //   const convertToHTML = document.createElement("p");
    //   convertToHTML.innerHTML = field.value;
    //   form.setFieldValue(field.name, convertToHTML.textContent);
    // };

    return {
      isEdit: isEdit,
      ...rest,
      switchIsEdit,
      value: field.value,
      name: field.name,
      // customOnBlur,
    };
  })
);

export default enhancer(LatexInput);
