import { useHistory } from "react-router-dom";
import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Backdrop, Box } from "components";
import { QuestionList } from "components/advance";
import {
  getErrorMessage,
  gql,
  notifyError,
  notifySuccess,
  paths,
} from "utils/helper";
import { Refresh as RefreshIcon, Save as SaveIcon } from "@material-ui/icons";
import { map } from "lodash";
import ENV from "env";

const QuestionGeneratePage = (props) => (
  <PageContent
    title="AI-Generated Question"
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: paths.questionsPath(props.courseId), label: "คำถาม" },
      {
        path: paths.questionEditPath(props.courseId, props.questionId),
        label: "แก้ไขคำถาม",
      },
      { path: null, label: "AI-generated question" },
    ]}
    pageActions={[
      {
        children: "Regenerate",
        startIcon: <RefreshIcon />,
        onClick: () => paths.questionNewPath(props.courseId).push(),
      },
      {
        children: "Save",
        startIcon: <SaveIcon />,
        onClick: props.handleSaveQuestions,
        color: "primary",
      },
    ]}
  >
    <Box width="100%" mb={-6}>
      {/* <Typography variant="h4" mb={4}>
        รายการคำถาม
      </Typography> */}
      {/* {props.questions && (
        <FieldArray name="answerConfig.mcqs.choices" component={QuestionList} />
      )} */}

      <QuestionList
        questionList={props.questions}
        onSelectedQuestionsChange={props.handleSelectedQuestions}
      />
    </Box>
  </PageContent>
);

export const API = {
  CREATE_QUESTION: gql`
    mutation CREATE_QUESTION(
      $name: String
      $type: String
      $description: String
      $answerConfig: JSON
      $tagInfo: JSON
      $hardAnswer: String
      $category: [String!]
      $paragraphId: String
      $documents: [Upload!]
      $published: Boolean
      $isAiGenerated: Boolean
      $modelQuestionId: String
    ) {
      createQuestion(
        input: {
          name: $name
          type: $type
          description: $description
          answerConfig: $answerConfig
          tagInfo: $tagInfo
          hardAnswer: $hardAnswer
          category: $category
          paragraphId: $paragraphId
          documents: $documents
          published: $published
          isAiGenerated: $isAiGenerated
          modelQuestionId: $modelQuestionId
        }
      ) {
        question {
          id
          name
          type
          description
          paragraphId
          answerConfig
          tagInfo
          createdAt
          updatedAt
          hardAnswer
          category
          paragraphId
        }
      }
    }
  `,
  FETCH_QUESTIONS: gql`
    query FETCH_QUESTIONS(
      $page: Float
      $pageSize: Float
      $filters: JSON
      $sorts: JSON
    ) {
      questions(
        input: { filters: $filters, sorts: $sorts }
        paginate: { page: $page, pageSize: $pageSize }
      ) {
        questions {
          id
          name
          type
          tagInfo
          updatedAt
          published
        }
        paginate {
          page
          pageSize
          rowCount
        }
      }
    }
  `,
  DELETE_QUESTIONS: gql`
    mutation DELETE_QUESTIONS($ids: [String!]!) {
      deleteQuestions(input: { ids: $ids })
    }
  `,
  SYNC_QUESTIONS: gql`
    mutation SYNC_QUESTIONS {
      syncQuestions
    }
  `,
  FETCH_TAGS: gql`
    query FETCH_TAGS($input: TagsInput!) {
      tags(input: $input) {
        configs
      }
    }
  `,
  FETCH_QUESTION: gql`
    query FETCH_QUESTION($id: String!) {
      question(id: $id) {
        id
        name
        type
        description
        paragraphId
        answerConfig
        tagInfo
        createdAt
        updatedAt
        hardAnswer
        category
        published
        documents {
          filename
          url
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      useMemo,
      useQuery,
      useState,
      useEffect,
      useCallback,
      useMutation,
      useParams,
      useUrlPath,
    } = hooks;
    const { id, amount } = useParams();
    const { courseId } = useUrlPath();
    const history = useHistory();

    const { loading, data, error, refetch } = useQuery(API.FETCH_QUESTIONS, {
      variables: {
        sorts: [{ field: "updatedAt", sort: "desc" }],
        page: 0,
        pageSize: 100,
      },
    });

    const fetchTags = useQuery(API.FETCH_TAGS, {
      variables: {
        input: {
          course: courseId,
        },
      },
    });
    const fetchQuestion = useQuery(API.FETCH_QUESTION, {
      variables: { id },
    });
    const [createQuestion] = useMutation(API.CREATE_QUESTION);

    const [syncQuestions] = useMutation(API.SYNC_QUESTIONS);
    const [questionData, setQuestionData] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const parentQuestion = useMemo(() => {
      if (fetchQuestion.loading) {
        return null;
      }
      if (fetchQuestion.error) {
        const message = getErrorMessage(fetchQuestion.error);
        notifyError(message);
        paths.questionsPath(courseId).push();
        return null;
      }
      return fetchQuestion.data.question;
    }, [fetchQuestion.loading, fetchQuestion.data, fetchQuestion.error]);

    const handleSelectedQuestions = (questions) => {
      setSelectedQuestions(questions);
    };

    const handleSaveQuestions = async () => {
      Backdrop.open();
      try {
        const createPromises = selectedQuestions.map(
          async (question, index) => {
            const questionData = {
              ...parentQuestion,
              name: `${parentQuestion.name}_AI${new Date().toLocaleString()}_${
                index + 1
              }`,
              description: question.question,
              hardAnswer: question.hardAnswer,
              answerConfig: {
                mcqs: {
                  choices: question.choices.map((choice) => ({
                    label: choice.label,
                    value: choice.value,
                    isCorrect: question.answer === choice.value,
                  })),
                  answer: question.answer,
                },
              },
              isAiGenerated: true,
              modelQuestionId: parentQuestion.id,
            };

            await createQuestion({ variables: questionData });
          }
        );

        await Promise.all(createPromises);
        notifySuccess("All questions saved successfully");
        paths.questionsPath(courseId).push();
      } catch (error) {
        notifyError(error.message);
        console.log("error from createPromises", error.message);
      } finally {
        Backdrop.close();
      }
    };

    const getCourseData = async (courseId, questionId, quantity) => {
      try {
        const url = `${ENV.DJANGO_API_ENDPOINT}/teacher/courses/${courseId}/ai/generate/`;
        const requestBody = {
          question_id: questionId, // test question => "f49095f7-19fd-46f3-842c-d32111d604d6",
          quantity: amount,
          history: [],
          comment: "-",
        };
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching data: ", error.response);
        alert("Error in generating exam");
        history.goBack();
      }
    };

    const fetchCourseData = useCallback(
      async (courseId, questionId, quantity) => {
        try {
          Backdrop.open();
          const questionData = await getCourseData(
            courseId,
            questionId,
            quantity
          );

          if (questionData && questionData.exams) {
            const questions = questionData.exams.map((exam) => ({
              ...exam,
              id: exam.question,
              name: exam.question,
              content: exam.question,
              choices: exam.choices,
              hardAnswer: exam.choices.find(
                (choice) => choice.value === exam.answer
              ).label,
              explanation: exam.explanation,
            }));
            setQuestionData(questions);
          } else {
            setQuestionData([]);
          }
          console.log("questionData", questionData);
        } catch (error) {
          console.error("Error fetching data: ", error);
          setQuestionData([]);
        } finally {
          Backdrop.close();
        }
      },
      []
    );

    useEffect(() => {
      if (id && amount) {
        fetchCourseData(courseId, id, amount);
      }
    }, [id, amount]);

    const tagsOptions = useMemo(() => {
      const options = [];
      const tagsData = fetchTags.data?.tags?.configs[0]?.children ?? [];
      const convertTagsDataToOptions = (
        data,
        deforeCode = [],
        deforeTitle = ""
      ) => {
        data.forEach((item) => {
          const newTitle = deforeTitle
            ? `${deforeTitle} > ${item.title}`
            : ` ${item.title}`;
          if (item.children.length > 0) {
            convertTagsDataToOptions(
              item.children,
              [...deforeCode, item.code],
              newTitle
            );
          } else {
            const newArray = [...deforeCode];
            const first = newArray.shift();

            options.push({
              label: `${
                first +
                (item.difficultyLevel
                  ? item.difficultyLevel.toString().padStart(2, "0")
                  : "") +
                newArray.join("") +
                item.code
              } - ${newTitle}`,
              value: item.id,
            });
            return null;
          }
        });
      };
      convertTagsDataToOptions(tagsData, []);
      return options;
    }, [fetchTags.data]);

    const tagsOptionsForFilter = useMemo(
      () =>
        map(tagsOptions, (value) => ({
          label: value.label,
          value: value.value,
        })),
      [tagsOptions]
    );

    const syncQuestionsEvent = useCallback(async () => {
      try {
        await syncQuestions();
        await refetch();
        notifySuccess("ดำเนินการลบคำถามสำเร็จ");
      } catch (e) {
        notifyError(e);
      }
    }, [syncQuestions, refetch]);

    return {
      courseId,
      loading,
      syncQuestionsEvent,
      tagsOptions,
      tagsOptionsForFilter,
      rowCount: data?.questions?.paginate?.rowCount ?? 0,
      refetch,
      questionId: id,
      questions: questionData,
      handleSelectedQuestions: handleSelectedQuestions,
      handleSaveQuestions: handleSaveQuestions,
    };
  })
);

export default enhancer(QuestionGeneratePage);
