import React from "react";
import { compose, withStores, withHooks } from "enhancers";
import styled from "styled-components/macro";

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 12px 16px;
  transition: all 0.2s;

  :hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }
`;

const MenuBrowse = ({
  Icon,
  getRootProps,
  getInputProps,
  children,
  ...props
}) => (
  <MenuItem {...getRootProps()}>
    <input autoComplete="off" value={undefined} {...getInputProps()} />
    {children}
  </MenuItem>
);

const enhancer = compose(
  withStores((stores) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withHooks((props, hooks) => {
    const { options, accept, multiple = false, onBrowse, children } = props;

    const { useState, useCallback, useDropzone } = hooks;

    const [loading, setLoading] = useState(false);
    const [anchorEl] = useState(null);
    const expanded = Boolean(anchorEl);

    const onDrop = useCallback(
      async (files) => {
        if (onBrowse) {
          setLoading(true);
          try {
            await onBrowse(files[0]);
          } catch (e) {}
          setLoading(false);
        }
        setLoading(false);
      },
      [onBrowse]
    );

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept,
      multiple,
    });

    return {
      expanded,
      options,
      anchorEl,
      getRootProps,
      getInputProps,
      loading,
      children,
    };
  })
);

export default enhancer(MenuBrowse);
