import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Backdrop, Box, ExcelGenerator, Table, Typography } from "components";
import {
  downloadFileFromS3,
  gql,
  notifyError,
  notifySuccess,
  paths,
} from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { filter, map, uniqBy } from "lodash";
import { format, parseISO } from "date-fns";

const ExamIndexPage = (props) => (
  <PageContent
    title="ข้อสอบ (Exam)"
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: null, label: "ข้อสอบ (Exam)" },
    ]}
    pageActions={[
      {
        children: "สร้างข้อสอบ",
        startIcon: <AddIcon />,
        onClick: () => paths.examNewPath(props.courseId).push(),
        color: "primary",
      },
    ]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการข้อสอบ
      </Typography>
      <Table
        columns={[
          { width: 185, field: "transactionId", headerName: "รหัส" },
          {
            width: 185,
            field: "studentEnrollId",
            headerName: "student enroll id",
            type: "singleSelect",
            valueOptions: props.studentEnrollIdOptions,
          },
          {
            width: 220,
            field: "createdAt",
            headerName: "เวลาสร้าง",
            sortable: false,
            type: "dateTime",
            valueFormatter: (params) => {
              if (params.value == null) {
                return "";
              }

              return format(parseISO(params.value), "dd/MM/yyyy, HH:mm");
            },
          },
          {
            width: 220,
            field: "sentAt",
            headerName: "เวลาส่ง",
            sortable: false,
            type: "dateTime",
            valueFormatter: (params) => {
              if (params.value == null) {
                return "";
              }
              return format(parseISO(params.value), "dd/MM/yyyy, HH:mm");
            },
          },
          {
            width: 190,
            field: "menus",
            headerName: "action",
            filterable: false,
            sortable: false,
            type: "menus",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        density="compact"
        autoHeight
        disableSelectionOnClick
        includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_EXAMS: gql`
    query FETCH_EXAMS($course: String!) {
      exams(course: $course) {
        id
        config
        transactionId
        studentEnrollId
        originalExamAttachmentId
        originalAnsweredExamAttachmentId
        submittedExamAttachmentId
        answeredExamAttachmentId
        labeledExamAttachmentId
        sentAt
        createdAt
      }
    }
  `,
  SUBMIT_EXAMS: gql`
    mutation SUBMIT_EXAMS($id: String, $pdf: Upload!) {
      submitExams(input: { id: $id, pdf: $pdf }) {
        id
      }
    }
  `,
  FETCH_QUESTIONS_BY_EXAM_ID: gql`
    query FETCH_QUESTIONS_BY_EXAM_ID($id: String!) {
      questionsByExamId(id: $id) {
        questionId
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      useMemo,
      useQuery,
      useEffect,
      useCallback,
      useMutation,
      useUrlPath,
    } = hooks;
    const { courseId } = useUrlPath();
    const { loading, data, error, refetch } = useQuery(API.FETCH_EXAMS, {
      variables: {
        course: courseId,
      },
    });
    const [submitExams] = useMutation(API.SUBMIT_EXAMS);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const submitExam = useCallback(
      async (params, file) => {
        try {
          Backdrop.open();
          await submitExams({ variables: { id: params.id, pdf: file } });
          await refetch();
          notifySuccess("upload สำเร็จ");
          Backdrop.close();
        } catch (e) {
          notifyError(e);
          Backdrop.close();
        }
      },
      [submitExams, refetch]
    );

    const downloadExam = useCallback(
      (id) => async () => {
        await downloadFileFromS3(id);
      },
      []
    );

    const downloadQuestionConfigs = useCallback(async (items, row) => {
      try {
        const dataForGenerateExcel = map(
          row.row.config.question_ids,
          (questionId, index) => {
            return {
              questionNo: index + 1,
              url: `${window.location.origin}/questions/${questionId}/edit`,
            };
          }
        );
        ExcelGenerator.generate({
          fileName: `configs`,
          columns: [
            {
              title: "ข้อที่",
              field: "questionNo",
            },
            {
              title: "url คำถาม",
              field: "url",
            },
          ],
          data: dataForGenerateExcel,
        });
      } catch (error) {
        const message =
          error.message ?? "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน";
        Notification.error(message);
      }
    }, []);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.exams.map((exam) => {
        const { ...rest } = exam;
        const menus = [
          {
            children: "ส่งข้อสอบ",
            onBrowse: submitExam,
          },
          {
            children: "Download",
            onClick: downloadQuestionConfigs,
          },
        ];
        [
          { label: "ข้อสอบต้นฉบับ", key: "originalExamAttachmentId" },
          {
            label: "เฉลยข้อสอบต้นฉบับ",
            key: "originalAnsweredExamAttachmentId",
          },
          { label: "ข้อสอบนำส่ง", key: "submittedExamAttachmentId" },
          { label: "ข้อสอบตรวจแล้ว", key: "answeredExamAttachmentId" },
          { label: "ข้อสอบติดแท็ก", key: "labeledExamAttachmentId" },
        ].forEach((menuData) => {
          if (rest[menuData.key]) {
            menus.push({
              children: menuData.label,
              onClick: downloadExam(rest[menuData.key]),
            });
          }
        });
        return {
          ...rest,
          menus,
          studentEnrollId: rest.studentEnrollId ? rest.studentEnrollId : "",
        };
      });
    }, [
      loading,
      data,
      error,
      submitExam,
      downloadExam,
      downloadQuestionConfigs,
    ]);

    const studentEnrollIdOptions = useMemo(
      () =>
        filter(
          uniqBy(
            map(data?.exams, (item) => ({
              label: item.studentEnrollId,
              value: item.studentEnrollId,
            })),
            "value"
          ),
          (option) => option.value
        ),
      [data]
    );
    return {
      courseId,
      tableData,
      loading,
      submitExam,
      studentEnrollIdOptions,
    };
  })
);

export default enhancer(ExamIndexPage);
