import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  ErrorMessage,
  Notification,
} from "components";
import { gql, paths } from "utils/helper";

const AdminNewPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Form>
      <Box display="flex" flexDirection="column">
        <Typography variant="h4">กรอกอีเมลของผู้ดูแลระบบ</Typography>
        <Typography variant="body1" color="textSecondary" mt={2}>
          ลิงก์เพื่อเข้าใช้งานระบบจะถูกส่งไปที่อีเมลนี้
        </Typography>
        <ErrorMessage name="__error__" mt={6} />
        <Field
          component={TextField.Email}
          name="email"
          label="อีเมล"
          required
          width={512}
          mt={6}
        />
        <Button type="submit" color="primary" width={74} p={0} mt={10}>
          ส่งเมล
        </Button>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  CREATE_ADMIN: gql`
    mutation CREATE_ADMIN($email: String!) {
      createAdmin(input: { email: $email }) {
        admin {
          code
          configs
          createdAt
          email
          firstName
          id
          isSuperAdmin
          phoneNumber
          slug
          updatedAt
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      email: "",
    }),
  }),
  withHooks((props, hooks) => {
    const { setErrors } = props;
    const { useMutation, useMemo, useHandleSubmit } = hooks;
    const [createAdmin] = useMutation(API.CREATE_ADMIN);

    const title = `เพิ่มผู้ดูแลระบบ`;

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.adminsPath(), label: "ผู้ดูแลระบบ" },
        { path: null, label: title },
      ];
    }, [title]);

    useHandleSubmit(
      async (values) => {
        try {
          const { email } = values;
          await createAdmin({
            variables: { email: email?.trim()?.toLowerCase() },
          });
          paths.adminsPath().push();
          Notification.success("ส่งเมลสำเร็จ");
        } catch (e) {
          setErrors({
            __error__: "มีอีเมลนี้ในระบบแล้ว",
            email: "มีอีเมลนี้ในระบบแล้ว",
          });
        }
      },
      [createAdmin]
    );

    return { title, breadcrumbs };
  })
);

export default enhancer(AdminNewPage);
