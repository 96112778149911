import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Grid, Typography } from "components"; // Add this import
import { gql, paths } from "utils/helper";
import MediaCard from "components/common/MediaCard";

const CourseIndexPage = (props) => (
  <PageContent
    title="ผู้ดูแลระบบ"
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: null, label: "คอร์สที่ดูแลอยู่" },
    ]}
    pageActions={[]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายชื่อคอร์สที่ดูแลอยู่
      </Typography>
      <Grid container spacing={6}>
        {props.managedCourses.map((course) => (
          <Grid item xs={12} md={6}>
            <MediaCard
              title={course.name}
              image={course.banner}
              onClick={() => paths.questionsPath(course.id).push()}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  </PageContent>
);

export const API = {
  MANAGED_COURSES: gql`
    query FETCH_MANAGED_COURSES {
      managedCourses {
        id
        name
        banner
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useQuery, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.MANAGED_COURSES);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const managedCourses = useMemo(() => {
      return data?.managedCourses || [];
    }, [data]);

    return {
      loading,
      managedCourses,
    };
  })
);

export default enhancer(CourseIndexPage);
