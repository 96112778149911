import { compose, withFormik, withHooks } from "enhancers";
import {
  Backdrop,
  Box,
  Field,
  Modal,
  Notification,
  TextField,
} from "components";
import { Flare as MagicIcon } from "@material-ui/icons";
import { getErrorMessage, gql, notifyError, paths } from "utils/helper";
import { QuestionNewPage } from "./new";
import { cloneDeep, find, map as lodashMap, uniq, uniqBy } from "lodash";
import {
  addNodeUnderParent,
  changeNodeAtPath,
  getNodeAtPath,
  map,
  removeNodeAtPath,
} from "react-sortable-tree";
import katex from "katex";
import { Quill } from "react-quill";
import "katex/dist/katex.min.css";

import ImageResize from "quill-image-resize";

window.katex = katex;

Quill.register("modules/imageResize", ImageResize);

export const API = {
  FETCH_QUESTION: gql`
    query FETCH_QUESTION($id: String!) {
      question(id: $id) {
        id
        name
        type
        modelQuestionId
        childrenQuestionIds
        description
        paragraphId
        answerConfig
        tagInfo
        createdAt
        updatedAt
        hardAnswer
        category
        published
        documents {
          filename
          url
        }
      }
    }
  `,
  UPDATE_QUESTION: gql`
    mutation UPDATE_QUESTION(
      $id: String
      $name: String
      $type: String
      $description: String
      $answerConfig: JSON
      $tagInfo: JSON
      $hardAnswer: String
      $category: [String!]
      $published: Boolean
      $paragraphId: String
      $documents: [Upload!]
    ) {
      updateQuestion(
        input: {
          id: $id
          name: $name
          type: $type
          description: $description
          answerConfig: $answerConfig
          tagInfo: $tagInfo
          hardAnswer: $hardAnswer
          category: $category
          published: $published
          paragraphId: $paragraphId
          documents: $documents
        }
      ) {
        question {
          id
          name
          type
          description
          paragraphId
          answerConfig
          tagInfo
          createdAt
          updatedAt
          hardAnswer
          category
          published
          documents {
            filename
            url
          }
        }
      }
    }
  `,
  PUBLISH_QUESTION: gql`
    mutation PUBLISH_QUESTION($id: String, $published: Boolean) {
      publishQuestion(input: { id: $id, published: $published }) {
        question {
          id
          name
          type
          description
          paragraphId
          answerConfig
          tagInfo
          createdAt
          updatedAt
          hardAnswer
          category
          published
        }
      }
    }
  `,
  FETCH_TAGS: gql`
    query FETCH_TAGS($input: TagsInput!) {
      tags(input: $input) {
        configs
      }
    }
  `,
  FETCH_QUESTIONS: gql`
    query FETCH_QUESTIONS(
      $page: Float
      $pageSize: Float
      $filters: JSON
      $sorts: JSON
    ) {
      questions(
        input: { filters: $filters, sorts: $sorts }
        paginate: { page: $page, pageSize: $pageSize }
      ) {
        questions {
          id
          answerConfig
          category
          tagInfo
          name
        }
      }
    }
  `,
  FETCH_PARAGRAPHS: gql`
    query FETCH_PARAGRAPHS($name: String, $course: String) {
      paragraphs(input: { name: $name, course: $course }) {
        id
        name
      }
    }
  `,
};

const ModalContent = ({ questionName, handleQuantityChange }) => {
  return (
    <Box display="flex" flexDirection="row" spacing={4}>
      <Field
        disabled
        name="name"
        value={questionName}
        component={TextField}
        label="คำถาม"
        width={512}
        style={{ marginRight: 16 }}
      />
      <Field
        name="quan"
        component={TextField}
        label="จำนวน"
        width={512}
        onChange={handleQuantityChange}
      />
    </Box>
  );
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withHooks((props, hooks) => {
    const { setValues, values, setFieldValue } = props;
    const {
      useMutation,
      useMemo,
      useHandleSubmit,
      useParams,
      useQuery,
      useState,
      useEffect,
      useCallback,
      useUrlPath,
    } = hooks;
    const { courseId } = useUrlPath();
    const { id } = useParams();
    const [modalQuantity, setModalQuantity] = useState("");
    const [generate, setGenerate] = useState(false);
    const [updateQuestion] = useMutation(API.UPDATE_QUESTION);
    const fetchParagraphs = useQuery(API.FETCH_PARAGRAPHS, {
      variables: {
        course: courseId,
      },
    });
    const fetchTags = useQuery(API.FETCH_TAGS, {
      variables: {
        input: {
          course: courseId,
        },
      },
    });
    const fetchAllQuestions = useQuery(API.FETCH_QUESTIONS, {
      variables: {
        filters: [
          {
            columnField: "course",
            operatorValue: "equals",
            value: courseId,
          },
        ],
      },
    });

    const handleQuantityChange = (e) => {
      setModalQuantity(e.target.value);
    };

    const generateQuestion = useCallback((questionName) => {
      Modal.open({
        title: "Auto-generated with AI",
        children: (
          <ModalContent
            questionName={questionName}
            handleQuantityChange={handleQuantityChange}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "Generate",
        onOk: async ({ close }) => {
          setGenerate(true);
          close();
        },
      });
    }, []);

    useEffect(() => {
      if (modalQuantity && generate) {
        paths.questionAIGeneratePath(courseId, id, modalQuantity).push();
      }
    }, [courseId, id, modalQuantity, generate]);

    useEffect(() => {
      fetchTags.refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchTags.refetch]);

    const title = `แก้ไขคำถาม`;
    const allQuestions = fetchAllQuestions.data?.questions?.questions;
    const questions = useMemo(() => {
      return (
        allQuestions?.filter((question) => question.type === "Grid-in") || []
      );
    }, [allQuestions]);

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.questionsPath(), label: "คำถาม" },
        { path: null, label: title },
      ];
    }, [title]);

    const pageActions = [
      {
        children: "Auto-generated with AI",
        startIcon: <MagicIcon />,
        onClick: () => generateQuestion(fetchQuestion.data.question.name),
        color: "primary",
      },
    ];

    const getTitle = useCallback((node, path) => {
      if (path.length === 4 || path.length === 0) {
        return node.title;
      }
      if (path.length === 1) {
        return `line ${node.children.length + 1}`;
      }
      if (path.length === 2) {
        if (node.children.length === 0) {
          return "Main Tag";
        }

        return `Sub Tag ${node.children.length}`;
      }
    }, []);

    const getQuestionName = useCallback(
      (questionId) => {
        const question = find(allQuestions, { id: questionId });
        return question?.name;
      },
      [allQuestions]
    );

    const fetchQuestion = useQuery(API.FETCH_QUESTION, {
      variables: { id },
    });

    const question = useMemo(() => {
      Backdrop.open();
      if (fetchQuestion.loading) {
        return null;
      }
      if (fetchQuestion.error) {
        const message = getErrorMessage(fetchQuestion.error);
        notifyError(message);
        paths.questionsPath(courseId).push();
        return null;
      }
      return fetchQuestion.data.question;
    }, [fetchQuestion.loading, fetchQuestion.data, fetchQuestion.error]);

    useEffect(() => {
      setValues({
        ...question,
        tagInfo: cloneDeep(question?.tagInfo),
      });
      Backdrop.close();
    }, [setValues, question]);

    const paragraphOptions = useMemo(() => {
      return fetchParagraphs.data?.paragraphs?.map((paragraph) => ({
        label: paragraph.name,
        value: paragraph.id,
      }));
    }, [fetchParagraphs.data]);

    const typeOptions = useMemo(
      () => [
        { label: "MCQs", value: "mcqs" },
        { label: "Grid-in", value: "Grid-in" },
      ],
      []
    );

    const categoryOptions = useMemo(() => {
      let categoryList = [];
      allQuestions?.forEach((question) => {
        if (question.category) {
          categoryList = categoryList.concat(question.category);
        }
      });
      categoryList = uniq(categoryList);

      return lodashMap(categoryList, (item) => ({
        label: item,
        value: item,
      }));
    }, [allQuestions]);

    const prefillOptions = useMemo(() => {
      return lodashMap(allQuestions, (item) => ({
        label: item.name,
        value: item.id,
      }));
    }, [allQuestions]);

    const tagsOptions = useMemo(() => {
      const options = [];
      const tagsData = fetchTags.data?.tags?.configs[0]?.children ?? [];
      const convertTagsDataToOptions = (
        data,
        deforeCode = [],
        deforeTitle = ""
      ) => {
        data.forEach((item) => {
          const newTitle = deforeTitle
            ? `${deforeTitle} > ${item.title}`
            : ` ${item.title}`;
          if (item.children.length > 0) {
            convertTagsDataToOptions(
              item.children,
              [...deforeCode, item.code],
              newTitle
            );
          } else {
            const newArray = [...deforeCode];
            const first = newArray.shift();

            if (first) {
              options.push({
                label: `${
                  first +
                  (item.difficultyLevel
                    ? item.difficultyLevel.toString().padStart(2, "0")
                    : "") +
                  newArray.join("") +
                  item.code
                } - ${newTitle}`,
                value: item.id,
              });
            } else {
              options.push({
                label: `${
                  item.code +
                  (item.difficultyLevel
                    ? item.difficultyLevel.toString().padStart(2, "0")
                    : "") +
                  newArray.join("")
                } - ${newTitle}`,
                value: item.id,
              });
            }

            return null;
          }
        });
      };
      convertTagsDataToOptions(tagsData, []);
      return options;
    }, [fetchTags.data]);

    useHandleSubmit(
      async (values) => {
        Backdrop.open();
        let newValues = cloneDeep(values);
        try {
          if (newValues.type === "mcqs") {
            const answer = find(
              newValues.answerConfig?.mcqs?.choices ?? [],
              (choice) => choice?.isCorrect === "true"
            );
            if (answer) {
              newValues.answerConfig.mcqs = {
                ...newValues.answerConfig.mcqs,
                answer: answer.value,
              };
            }
          }
          await updateQuestion({ variables: newValues });
          paths.questionsPath(courseId).push();
          Notification.success("บันทึกข้อมูลสำเร็จ");
          Backdrop.close();
        } catch (e) {
          notifyError(e.message);
          Backdrop.close();
        }
      },
      [updateQuestion]
    );

    const submit = useCallback(async () => {
      let newValues = cloneDeep(values);
      try {
        if (newValues.type === "mcqs") {
          const answer = find(
            newValues.answerConfig?.mcqs?.choices ?? [],
            (choice) => choice?.isCorrect === "true"
          );
          if (answer) {
            newValues.answerConfig.mcqs = {
              ...newValues.answerConfig.mcqs,
              answer: answer.value,
            };
          }
        }
        await updateQuestion({ variables: newValues });
        paths.questionsPath(courseId).push();
        Notification.success("บันทึกข้อมูลสำเร็จ");
      } catch (e) {
        notifyError(e.message);
      }
    }, [values, updateQuestion]);

    const treeData = useMemo(() => values.tagInfo?.list ?? [], [
      values.tagInfo?.list,
    ]);

    const onTreeChange = useCallback(
      (treeData) => {
        const newTreeData = map({
          treeData,
          getNodeKey: ({ treeIndex }) => treeIndex,
          callback: ({ path, node, parentNode, lowerSiblingCounts }) => {
            if (path.length === 2) {
              node.title = `line ${
                parentNode.children.length -
                lowerSiblingCounts[lowerSiblingCounts.length - 1]
              }`;
            }
            if (path.length === 3) {
              if (parentNode.children.length - 1 === lowerSiblingCounts[2]) {
                node.title = "Main Tag";
              } else {
                node.title = `Sub Tag ${
                  parentNode.children.length -
                  lowerSiblingCounts[lowerSiblingCounts.length - 1] -
                  1
                }`;
              }
            }

            return node;
          },
        });

        setFieldValue("tagInfo.list", newTreeData);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setFieldValue]
    );

    const addNode = useCallback(
      (node, parentNode, lowerSiblingCounts, path) => () => {
        const title = getTitle(node, path);
        const newTreedata = addNodeUnderParent({
          treeData: treeData,
          parentKey: path[path.length - 1],
          expandParent: true,
          minimumTreeIndex: treeData.length,
          newNode: { title: title, children: [] },
          getNodeKey: ({ treeIndex }) => treeIndex,
        }).treeData;
        setFieldValue("tagInfo.list", newTreedata);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [treeData, setFieldValue]
    );

    const removeNode = useCallback(
      (path) => () => {
        const newTreedata = removeNodeAtPath({
          treeData: treeData,
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
        });
        path.splice(-1);
        const parentNode = getNodeAtPath({
          treeData: newTreedata,
          path: path,
          getNodeKey: ({ treeIndex }) => treeIndex,
        });
        const newChildren = parentNode.node.children.map(
          (childrenItem, index) => {
            if (path.length === 1) {
              return { ...childrenItem, title: `line ${index + 1}` };
            }
            if (path.length === 2) {
              if (index === 0) {
                return { ...childrenItem, title: "Main Tag" };
              }

              return { ...childrenItem, title: `Sub Tag ${index}` };
            }

            return childrenItem;
          }
        );
        const treeDataNew = changeNodeAtPath({
          treeData: newTreedata,
          path,
          newNode: { ...parentNode.node, children: newChildren },
          getNodeKey: ({ treeIndex }) => treeIndex,
          expanded: true,
        });
        setFieldValue("tagInfo.list", treeDataNew);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [treeData, setFieldValue]
    );

    const editText = useCallback(
      (path, node, fieldName) => (e, value) => {
        const newTreedata = changeNodeAtPath({
          treeData: treeData,
          path,
          newNode: { ...node, [fieldName]: value?.value },
          getNodeKey: ({ treeIndex }) => treeIndex,
        });
        setFieldValue("tagInfo.list", newTreedata);
      },
      [treeData, setFieldValue]
    );

    const getOptionLabel = useCallback(
      (option) => {
        const customOption = typeof option === "object" ? option.value : option;

        const matchOption = find(tagsOptions, {
          value: customOption,
        });
        const value = matchOption?.label ?? "";

        return value ?? option.label;
      },
      [tagsOptions]
    );

    const labelListOptions = useMemo(() => {
      const labelList = [];
      questions?.forEach((question) => {
        if (question.answerConfig?.gridIn?.labelList) {
          labelList.push({
            label: question.answerConfig.gridIn.labelList.join(" "),
            value: question.answerConfig.gridIn.labelList.join(" "),
          });
        }
      });

      return uniqBy(labelList, "value");
    }, [questions]);

    const selectLabelList = useCallback(
      (e, value, reason, details) => {
        if (reason === "select-option") {
          value.splice(-1);
          setFieldValue(
            "answerConfig.gridIn.labelList",
            uniq([...value, ...details.option.split(" ")])
          );
          return;
        }
        if (reason !== "blur") {
          setFieldValue("answerConfig.gridIn.labelList", value);
        }
      },
      [setFieldValue]
    );

    const prefillTags = useCallback(() => {
      const question = find(allQuestions, { id: values.prefillTags });
      if (question) {
        setFieldValue("tagInfo", question.tagInfo);
      }
    }, [setFieldValue, values.prefillTags, allQuestions]);

    const canEdit = useMemo(() => !values.published, [values.published]);

    const initialized = !(fetchTags.loading || fetchQuestion.loading);

    return {
      title,
      breadcrumbs,
      pageActions,
      typeOptions,
      values,
      tagsOptions,
      treeData,
      onTreeChange,
      addNode,
      removeNode,
      editText,
      getTitle,
      getOptionLabel,
      initialized,
      categoryOptions,
      labelListOptions,
      selectLabelList,
      canEdit,
      paragraphOptions,
      submit,
      prefillOptions,
      prefillTags,
      getQuestionName,
    };
  })
);

export default enhancer(QuestionNewPage);
