import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Card } from "components";

import katex from "katex";
import "katex/dist/katex.min.css";

window.katex = katex;

const QuestionList = ({
  questionList: questions,
  onSelectedQuestionsChange,
}) => {
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const handleSelectQuestion = (questionId) => {
    const currentIndex = selectedQuestions.indexOf(questionId);
    const newSelectedQuestions = [...selectedQuestions];

    if (currentIndex === -1) {
      newSelectedQuestions.push(questionId);
    } else {
      newSelectedQuestions.splice(currentIndex, 1);
    }

    setSelectedQuestions(newSelectedQuestions);
    onSelectedQuestionsChange(
      newSelectedQuestions.map((qId) =>
        questions.find((q) => q.id.toString() === qId)
      )
    );
  };

  const handleSelectAll = () => {
    if (selectedQuestions.length === questions.length) {
      setSelectedQuestions([]);
    } else {
      setSelectedQuestions(questions.map((q) => q.id.toString()));
    }
    onSelectedQuestionsChange(
      selectedQuestions.length === questions.length ? [] : questions
    );
  };

  return (
    <Box>
      {questions && questions.length > 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={6}
        >
          <Typography variant="h4" flex={1}>
            Questions
          </Typography>
          <Button onClick={handleSelectAll}>
            {selectedQuestions.length === questions.length
              ? "Deselect All"
              : "Select All"}
          </Button>
        </Box>
      )}
      {questions &&
        questions.map((question, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedQuestions.includes(question.id.toString())}
                onChange={() => {
                  handleSelectQuestion(question.id.toString());
                  // console.log("question", question, question.choices.label);
                }}
              />
            }
            label={
              <Box style={{ display: "flex", width: "100%" }}>
                <Card
                  style={{ flex: 1 }}
                  // title={question.name}
                  content={
                    <Box>
                      <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{ __html: question.content }}
                      />
                      <List dense>
                        {question.choices &&
                          question.choices.map((choice, idx) => {
                            return (
                              <ListItem key={idx}>
                                <ListItemText
                                  primary={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span>{idx + 1}.</span>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: choice.label,
                                        }}
                                        style={{ marginLeft: "0.5em" }}
                                      />
                                    </div>
                                  }
                                />
                              </ListItem>
                            );
                          })}
                      </List>
                      <Typography variant="body2">
                        Answer: {question.answer}
                      </Typography>
                      <Typography variant="body2">
                        Hard Answer: {question.explanation}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            }
            style={{ display: "flex", width: "100%", margin: 0 }}
          />
        ))}
    </Box>
  );
};

export default QuestionList;
