import { compose, withHooks, withFormik } from "enhancers";
import { Notification } from "components";
import { getErrorMessage, gql, notifyError, paths } from "utils/helper";
import { ParagraphNewPage } from "./new";
import { cloneDeep, find } from "lodash";

export const API = {
  FETCH_PARAGRAPH: gql`
    query FETCH_PARAGRAPH($id: String!) {
      paragraph(id: $id) {
        id
        name
        description
      }
    }
  `,
  UPDATE_PARAGRAPH: gql`
    mutation UPDATE_PARAGRAPH(
      $id: String
      $name: String
      $description: String
    ) {
      updateParagraph(
        input: { id: $id, name: $name, description: $description }
      ) {
        paragraph {
          id
          name
          description
        }
      }
    }
  `,
};
const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withHooks((props, hooks) => {
    const { setValues, values } = props;
    const {
      useMutation,
      useMemo,
      useHandleSubmit,
      useParams,
      useQuery,
      useEffect,
      useUrlPath,
    } = hooks;
    const { courseId } = useUrlPath();
    const { id } = useParams();
    const [updateParagraph] = useMutation(API.UPDATE_PARAGRAPH);
    const fetchParagraph = useQuery(API.FETCH_PARAGRAPH, {
      variables: { id },
    });

    const title = `แก้ไขบทความ`;

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.paragraphsPath(), label: "บทความ" },
        { path: null, label: title },
      ];
    }, [title]);

    const paragraph = useMemo(() => {
      if (fetchParagraph.loading) {
        return null;
      }
      if (fetchParagraph.error) {
        const message = getErrorMessage(fetchParagraph.error);
        notifyError(message);
        paths.paragraphsPath(courseId).push();

        return null;
      }

      return fetchParagraph.data.paragraph;
    }, [fetchParagraph.loading, fetchParagraph.data, fetchParagraph.error]);

    useEffect(() => {
      setValues({
        ...paragraph,
      });
    }, [setValues, paragraph]);

    useHandleSubmit(
      async (values) => {
        let newValues = cloneDeep(values);
        try {
          if (newValues.type === "mcqs") {
            const answer = find(
              newValues.answerConfig?.mcqs?.choices ?? [],
              (choice) => choice?.isCorrect === "true"
            );
            if (answer) {
              newValues.answerConfig.mcqs = {
                ...newValues.answerConfig.mcqs,
                answer: answer.value,
              };
            }
          }
          await updateParagraph({ variables: newValues });
          paths.paragraphsPath(courseId).push();
          Notification.success("บันทึกข้อมูลสำเร็จ");
        } catch (e) {
          notifyError(e.message);
        }
      },
      [updateParagraph]
    );
    const treeData = useMemo(() => values.tagInfo?.list ?? [], [
      values.tagInfo?.list,
    ]);

    const initialized = true;

    return {
      title,
      breadcrumbs,
      values,
      treeData,
      initialized,
    };
  })
);

export default enhancer(ParagraphNewPage);
