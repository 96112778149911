import { useMemo } from "react";
import { useParams } from "react-router-dom";

const useUrlParam = (config) => {
  const pathParams = useParams();

  return useMemo(() => {
    const temp = {};
    for (const key in pathParams) {
      temp[key] = pathParams[key];
    }

    return temp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathParams]);
};

export default useUrlParam;
