import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Form,
  Field,
  TextField,
  Button,
  LatexEditor,
  Divider,
} from "components";
import { gql, notifyError, notifySuccess, paths } from "utils/helper";

import {} from "react-sortable-tree";
import "react-sortable-tree/style.css";

export const ParagraphNewPage = (props) =>
  props.initialized ? (
    <PageContent
      title={props.title}
      breadcrumbs={props.breadcrumbs}
      pageActions={props.pageActions}
    >
      <Form>
        <Box display="flex" flexDirection="column">
          <Field name="name" component={TextField} label="ชื่อ" width={512} />
          <Divider my={6} />
          <Field name="description" component={LatexEditor} label="บทความ" />
          <Divider my={6} />
          <Button type="submit" color="primary" width={74} p={0}>
            บันทึก
          </Button>
        </Box>
      </Form>
    </PageContent>
  ) : null;

export const API = {
  CREATE_PARAGRAPH: gql`
    mutation CREATE_PARAGRAPH(
      $name: String
      $description: String
      $course: String!
    ) {
      createParagraph(
        input: { name: $name, description: $description, course: $course }
      ) {
        paragraph {
          id
          name
          description
        }
      }
    }
  `,
};
const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withHooks((props, hooks) => {
    const { values } = props;
    const { useMutation, useMemo, useHandleSubmit, useUrlPath } = hooks;
    const { courseId } = useUrlPath();
    const [createParagraph] = useMutation(API.CREATE_PARAGRAPH);

    const title = `เพิ่มบทความ`;

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.paragraphsPath(), label: "บทความ" },
        { path: null, label: title },
      ];
    }, [title]);

    useHandleSubmit(
      async (values) => {
        try {
          await createParagraph({ variables: { ...values, course: courseId } });
          paths.paragraphsPath(courseId).push();
          notifySuccess("บันทึกข้อมูลสำเร็จ");
        } catch (e) {
          notifyError(e.message);
        }
      },
      [createParagraph]
    );

    const initialized = true;

    return {
      title,
      breadcrumbs,
      values,
      isNewPage: true,
      canEdit: true,
      initialized,
    };
  })
);

export default enhancer(ParagraphNewPage);
