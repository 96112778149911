import React, { useEffect } from "react";
import { compose, withHooks } from "enhancers";
import { Box, Typography, Field, Button, RadioGroup, Hidden } from "components";
import { get, range } from "lodash";
import { LatexEditor } from ".";

const LatexList = (props) => (
  <Box {...props}>
    {props.label && (
      <Box display="flex" alignItems="center" mb={6}>
        <Typography variant="h4" flex={1}>
          {props.label}
        </Typography>
      </Box>
    )}
    {range(props.amount).map((index) => {
      return (
        <Box key={index} display="flex" alignItems="center" mt={2}>
          <Field
            component={RadioGroup}
            name={`${props.name}[${index}].isCorrect`}
            options={[
              {
                label: "",
                value: "true",
              },
            ]}
            onChange={props.selectCorrectAnswer(index)}
            disabled={props.disabled}
          />
          <Field
            component={LatexEditor}
            name={`${props.name}[${index}].label`}
            mr={2}
            width="100%"
            disabled={props.disabled}
            editorMaxWidth="700px"
          />
          <Hidden when={props.disabled}>
            <Button
              startIcon="bigTrash"
              onClick={() => {
                props.remove(index);
              }}
            />
          </Hidden>
        </Box>
      );
    })}
    <Hidden when={props.disabled}>
      <Button startIcon="add" onClick={props.push} mt={6}>
        {props.addButtonLabel}
      </Button>
    </Hidden>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      form,
      name,
      push,
      remove,
      label,
      addButtonLabel,
      mt,
      type,
      hasGmap,
      disabled,
    } = props;
    const { useCallback } = hooks;
    const amount = get(form.values, name)?.length ?? 0;
    const setFieldValue = form.setFieldValue;

    useEffect(() => {
      range(amount).forEach((index) => {
        setFieldValue(`${name}[${index}].value`, index + 1);
      });
    }, [setFieldValue, name, amount]);

    const customPush = useCallback(() => {
      push({});
    }, [push]);

    const customRemove = useCallback(
      (index) => {
        remove(index);
      },
      [remove]
    );

    const selectCorrectAnswer = useCallback(
      (selectedIndex) => () => {
        range(amount).forEach((index) => {
          setFieldValue(
            `${name}[${index}].isCorrect`,
            index === selectedIndex ? "true" : null
          );
        });
      },
      [setFieldValue, name, amount]
    );

    return {
      name,
      amount,
      push: customPush,
      remove: customRemove,
      selectCorrectAnswer,
      label,
      addButtonLabel,
      mt,
      type,
      hasGmap,
      disabled,
    };
  })
);

export default enhancer(LatexList);
