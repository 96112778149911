import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  ErrorMessage,
  Notification,
} from "components";
import { getErrorMessage, gql, notifyError, paths } from "utils/helper";
import { isEqual } from "lodash";

const AdminEditPage = (props) => (
  <PageContent
    title={props.code}
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: paths.adminsPath(), label: "ผู้ดูแลระบบ" },
      { path: null, label: props.code },
    ]}
  >
    <Form>
      <Box display="flex" flexDirection="column">
        <Typography variant="h4">ข้อมูลทั่วไป</Typography>
        <ErrorMessage with="100%" name="__error__" mt={6} />
        <Box mt={6}>
          <Box display="flex">
            <Box display="flex" flex={1}>
              <Box display="flex" flexDirection="column" flex={1} mr={6}>
                <Field
                  component={TextField}
                  name="firstName"
                  type="text"
                  label="ชื่อ"
                  required
                />
                <Field
                  component={TextField.PhoneNumber}
                  name="phoneNumber"
                  label="เบอร์โทรศัพท์"
                  mt={6}
                  required
                />
                {/* <Field
                  component={TextField}
                  name="line"
                  type="text"
                  label="ไลน์"
                  mt={6}
                /> */}
              </Box>
              <Box display="flex" flexDirection="column" flex={1} mr={6}>
                <Field
                  component={TextField}
                  name="lastName"
                  type="text"
                  label="นามสกุล*"
                  required
                />
                <Field
                  component={TextField.Email}
                  name="email"
                  label="อีเมล"
                  disabled
                  mt={6}
                />
              </Box>
            </Box>
            {/* <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={327}
            >
              <Field component={UploadAvatar} name="avatarFile" />
            </Box> */}
          </Box>
          <Button
            type="submit"
            color="primary"
            width={74}
            mt={10}
            disabled={props.disabledSubmitButton}
          >
            บันทึก
          </Button>
        </Box>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  FETCH_ADMIN: gql`
    query FETCH_ADMIN($id: String!) {
      admin(id: $id) {
        id
        code
        firstName
        lastName
        phoneNumber
        email
      }
    }
  `,
  UPDATE_ADMIN: gql`
    mutation UPDATE_ADMIN(
      $id: String!
      $firstName: String
      $lastName: String
      $phoneNumber: String
      $email: String
    ) {
      editAdminInfo(
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          phoneNumber: $phoneNumber
          email: $email
        }
      ) {
        admin {
          id
          code
          firstName
          lastName
          phoneNumber
          email
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      line: "",
      avatarFile: null,
    }),
  }),
  withHooks((props, hooks) => {
    const { setErrors, setValues, values } = props;
    const {
      useQuery,
      useMutation,
      useMemo,
      useHandleSubmit,
      useEffect,
      useParams,
    } = hooks;
    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(API.FETCH_ADMIN, {
      variables: { id },
    });
    const [updateAdmin] = useMutation(API.UPDATE_ADMIN);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const admin = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.adminsPath().push();
        return null;
      }
      return data.admin;
    }, [loading, data, error]);

    useEffect(() => {
      setValues(admin);
    }, [setValues, admin]);

    useHandleSubmit(
      async (values) => {
        try {
          await updateAdmin({
            variables: values,
          });
          Notification.success("แก้ไขข้อมูลสำเร็จ");
        } catch (e) {
          const errorMessage = getErrorMessage(e);
          let errorMessageData = { __error__: errorMessage };

          if (errorMessage === "มีเบอร์โทรนี้ในระบบแล้ว") {
            errorMessageData = {
              ...errorMessageData,
              phoneNumber: errorMessage,
            };
          }

          if (errorMessage === "มีไลน์ไอดีในระบบแล้ว") {
            errorMessageData = {
              ...errorMessageData,
              line: errorMessage,
            };
          }
          setErrors(errorMessageData);
        }
      },
      [updateAdmin]
    );

    const disabledSubmitButton = useMemo(() => isEqual(values, data?.admin), [
      values,
      data,
    ]);

    return { code: admin?.code, disabledSubmitButton };
  })
);

export default enhancer(AdminEditPage);
