import paths from "routes/paths";

import { RouteType } from "types/routes";

// Menu Icon
import { ReactComponent as AdminIcon } from "vendor/menu_icon/admin.svg";

const MAIN_MENU: RouteType[] = [
  {
    id: "course",
    label: "คอร์ส (Course)",
    path: paths.coursesPath().toString(),
    header: "เมนู",
    icon: <AdminIcon />,
  },
  {
    id: "paragraph",
    label: "บทความ (Paragraph)",
    path: paths.paragraphsPath().toString(),
    icon: <AdminIcon />,
  },
  {
    id: "question",
    label: "คำถาม (Question)",
    path: paths.questionsPath().toString(),
    icon: <AdminIcon />,
  },
  {
    id: "tag",
    label: "แท็ก (Tag)",
    path: paths.tagsPath().toString(),
    icon: <AdminIcon />,
  },
  {
    id: "exam",
    label: "ข้อสอบ (Exam)",
    path: paths.examsPath().toString(),
    icon: <AdminIcon />,
  },
];

export default MAIN_MENU;
