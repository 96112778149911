import React from "react";
import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";

import ReactQuill, { Quill } from "react-quill";

import katex from "katex";
import "katex/dist/katex.min.css";

import ImageResize from "quill-image-resize";
import ImageCompress from "quill-image-compress";
import styled from "styled-components";
window.katex = katex;

Quill.register("modules/imageCompress", ImageCompress);
Quill.register("modules/imageResize", ImageResize);

const CustomReactQuill = styled(ReactQuill)`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};

  .ql-container {
    height: auto;
    font-family: "Arial", "Sarabun", sans-serif !important;
  }

  .ql-editor {
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "auto")};
    overflow-y: ${(props) => (props.maxHeight ? "auto" : "unset")};
    min-height: unset;
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : "15px"};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : "15px"};
  }
`;

const LatexEditor = ({ value, disabled, ...props }) => (
  <Box display="flex" flexDirection="column" {...props}>
    <Box mb={2}>
      {props.label && <Typography variant="h4">{props.label}</Typography>}
      {props.helperText && (
        <Typography variant="caption" color="rgba(0, 0, 0, 0.54)">
          {props.helperText}
        </Typography>
      )}
    </Box>
    <CustomReactQuill
      theme="snow"
      modules={props.modules}
      formats={props.formats}
      value={value ?? ""}
      onChange={(content, delta, source, editor) => {
        if (source === "user") {
          props.onChange({
            target: {
              name: props.name,
              value: content ?? null,
            },
          });
        }
      }}
      maxHeight={props.editorMaxHeight}
      maxWidth={props.editorMaxWidth}
      paddingLeft={props.editorPaddingLeft}
      paddingRight={props.editorPaddingRight}
      readOnly={disabled}
    />
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useState, useCallback, useMemo } = hooks;
    const { field, form, ...rest } = props;
    const [isEdit, setIsEdit] = useState(false);

    const switchIsEdit = useCallback(() => {
      setIsEdit(!isEdit);
    }, [isEdit]);

    const modules = useMemo(
      () => ({
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "formula"],
          [{ size: ["small", false, "large", "huge"] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["image"],
          [{ align: "" }, { align: "center" }, { align: "right" }],
        ],
        imageResize: {
          modules: ["Resize", "DisplaySize", "Toolbar"],
        },
      }),
      []
    );

    const formats = useMemo(
      () => [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "formula",
        "width",
        "align",
      ],
      []
    );

    return {
      isEdit: isEdit,
      ...rest,
      switchIsEdit,
      value: field.value,
      name: field.name,
      onChange: field.onChange,
      modules,
      formats,
    };
  })
);

export default enhancer(LatexEditor);
