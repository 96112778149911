import React from "react";
import { compose, withStores, withHooks } from "enhancers";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import styled from "styled-components/macro";
import { IconButton, Menu } from "@material-ui/core";
import MenuBrowse from "./MenuBrowse";

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 12px 16px;
  transition: all 0.2s;

  :hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }
`;

const DynamicMenu = ({
  Icon,
  SidebarFooterText,
  SidebarFooterSubText,
  getRootProps,
  getInputProps,
  row,
  ...props
}) => (
  <div>
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={props.handleClick}
    >
      <MoreVertIcon />
    </IconButton>
    <Menu
      anchorEl={props.anchorEl}
      keepMounted
      open={props.expanded}
      onClose={props.handleClose}
      transformOrigin={{
        vertical: "top",
        horizontal: -50,
      }}
    >
      {props.options.map((option) =>
        option.onBrowse ? (
          <MenuBrowse
            onBrowse={(file) => {
              option.onBrowse(row, file);
              props.handleClose();
            }}
            children={option.children}
          />
        ) : (
          <MenuItem
            onClick={(e) => {
              option.onClick(props.options, row);
              props.handleClose();
            }}
          >
            {option.children}
          </MenuItem>
        )
      )}
    </Menu>
  </div>
);

const enhancer = compose(
  withStores((stores) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withHooks((props, hooks) => {
    const { SidebarFooterText, SidebarFooterSubText, options, row } = props;

    const { useState, useCallback } = hooks;

    const [anchorEl, setAnchorEl] = useState(null);
    const expanded = Boolean(anchorEl);

    const handleClick = useCallback((event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    return {
      expanded,
      handleClick,
      handleClose,
      SidebarFooterText,
      SidebarFooterSubText,
      options,
      anchorEl,
      row,
    };
  })
);

export default enhancer(DynamicMenu);
