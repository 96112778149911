import qs from "qs";
import { last } from "lodash";
import { history } from "components/common/BrowserRouter";

// prettier-ignore
export class Path extends String {
  newTab = () => {
    window.open(this.toString());
  };

  push = (extendedPath: String = "") => {
    if (history) {
      history.push(`${this.toString()}${extendedPath ?? ""}`);
    }
  };

  reload = () => {
    window.location.href = this.toString();
  };

  makePath = (path: Function): Function => {
    return (...args: any[]) => {
      let pathMethod = typeof path === "string" ? () => path : path;
      let absolutePath = null;

      if (last(args) instanceof Object) {
        const params = args.slice(0, args.length - 1);
        const query = last(args);
        const queryString = qs.stringify(query);

        absolutePath = `${this}/${pathMethod(...params)}?${queryString}`;
      } else {
        absolutePath = `${this}/${pathMethod(...args)}`;
      }

      return new Path(absolutePath.replace(/\/+/, "/"));
    };
  };

  page404Path = this.makePath(() => `404`);
  page500Path = this.makePath(() => `500`);

  signInPath = this.makePath(() => `auth/sign-in`);
  signUpPath = this.makePath(() => `auth/sign-up`);
  setupPasswordPath = this.makePath(() => `auth/setup-password`);
  resetPasswordPath = this.makePath(() => `auth/reset-password`);

  adminsPath = this.makePath(() => `admins`);
  adminNewPath = this.makePath(() => `admins/new`);
  adminEditPath = this.makePath((id: any) => `admins/${id}/edit`);

  coursesPath = this.makePath(() => `courses`);

  paragraphsPath = this.makePath((courseId: string) => `courses/${courseId}/paragraphs`);
  paragraphNewPath = this.makePath((courseId: string) => `courses/${courseId}/paragraphs/new`);
  paragraphEditPath = this.makePath((courseId: string, id: string) => `courses/${courseId}/paragraphs/${id}/edit`);

  questionsPath = this.makePath((courseId: string) => `courses/${courseId}/questions`);
  questionNewPath = this.makePath((courseId: string) => `courses/${courseId}/questions/new`);
  questionEditPath = this.makePath((courseId: string, id: string) => `courses/${courseId}/questions/${id}/edit`);
  questionAIGeneratePath = this.makePath((courseId: string, id: string, amount: string | number) => `courses/${courseId}/questions/${id}/${amount}/ai-gen`);

  tagsPath = this.makePath((courseId: string) => `courses/${courseId}/tags`);

  examsPath = this.makePath((courseId: string) => `courses/${courseId}/exams`);
  examNewPath = this.makePath((courseId: string) => `courses/${courseId}/exams/new`);

  profilePath = this.makePath(() => `profile`);
  settingPath = this.makePath(() => `setting`);

  homePath = this.coursesPath;
}

export default new Path();
