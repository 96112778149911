import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Modal, Table, Typography } from "components";
import { gql, notifyError, notifySuccess, paths } from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import { map } from "lodash";

const ParagraphIndexPage = (props) => (
  <PageContent
    title="บทความ"
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: null, label: "บทความ" },
    ]}
    pageActions={[
      {
        children: "เพิ่มบทความ",
        startIcon: <AddIcon />,
        onClick: () => paths.paragraphNewPath(props.courseId).push(),
        color: "primary",
      },
    ]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการบทความ
      </Typography>
      <Table
        columns={[
          { width: 400, field: "name", headerName: "ชื่อ" },
          {
            width: 370,
            field: "updatedAt",
            headerName: "วันที่แก้ไขล่าสุด",
            type: "dateTime",
          },
          {
            width: 170,
            field: "menus",
            headerName: "คำถามที่เกี่ยวข้อง",
            filterable: false,
            sortable: false,
            type: "menus",
            align: "center",
          },
          {
            width: 95,
            field: "actions",
            headerName: "action",
            filterable: false,
            sortable: false,
            type: "actions",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClick={(row) =>
          paths.paragraphEditPath(props.courseId, row.id).push()
        }
        density="compact"
        autoHeight
        disableSelectionOnClick
        includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_PARAGRAPHS: gql`
    query FETCH_PARAGRAPHS($name: String, $course: String) {
      paragraphs(input: { name: $name, course: $course }) {
        id
        name
        description
        updatedAt
        canDelete
        questions {
          id
          name
        }
      }
    }
  `,
  DELETE_PARAGRAPHS: gql`
    mutation DELETE_PARAGRAPHS($ids: [String!]!) {
      deleteParagraphs(input: { ids: $ids }) {
        id
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      useMemo,
      useQuery,
      useEffect,
      useCallback,
      useMutation,
      useUrlPath,
    } = hooks;
    const { courseId } = useUrlPath();
    const { loading, data, error, refetch } = useQuery(API.FETCH_PARAGRAPHS, {
      variables: { course: courseId },
    });
    const [deleteParagraphs] = useMutation(API.DELETE_PARAGRAPHS);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const deleteParagraph = useCallback(
      async (params) => {
        const { id } = params.row;

        Modal.open({
          title: "ลบบทความ",
          children: `การดำเนินการนี้จะไม่สามารถย้อนกลับได้ แน่ใจใช่หรือไม่?`,
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ลบ",
          onOk: async ({ close }) => {
            try {
              await deleteParagraphs({ variables: { ids: [id] } });
              await refetch();
              close();
              notifySuccess("ดำเนินการลบบทความสำเร็จ");
            } catch (e) {
              notifyError(e.message);
              close();
            }
          },
        });
      },
      [deleteParagraphs, refetch]
    );

    const openQuestion = useCallback(
      (id) => () => {
        paths.questionEditPath(id).newTab();
      },
      []
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.paragraphs.map((paragraph) => {
        const { questions, ...rest } = paragraph;
        const menus = map(questions, (question) => ({
          children: question.name,
          onClick: openQuestion(question.id),
        }));
        return {
          ...rest,
          actions: [
            { Icon: TrashIcon, onClick: deleteParagraph, can: rest.canDelete },
          ],
          showMenu: !rest.canDelete,
          menus,
        };
      });
    }, [loading, data, error, deleteParagraph, openQuestion]);

    return {
      courseId,
      tableData,
      loading,
    };
  })
);

export default enhancer(ParagraphIndexPage);
