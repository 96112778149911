import React from "react";
import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Modal } from "components";
import { gql, notifyError, notifySuccess, paths } from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";

const AdminIndexPage = (props) => (
  <PageContent
    title="ผู้ดูแลระบบ"
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: null, label: "ผู้ดูแลระบบ" },
    ]}
    pageActions={
      props.currentUserIsSuperAdmin
        ? [
            {
              children: "เพิ่มผู้ดูแลระบบ",
              startIcon: <AddIcon />,
              onClick: () => paths.adminNewPath().push(),
              color: "primary",
            },
          ]
        : []
    }
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายชื่อผู้ดูแลระบบ
      </Typography>
      <Table
        columns={[
          { width: 130, field: "code", headerName: "รหัส" },
          { width: 200, field: "fullName", headerName: "ชื่อ นามสกุล" },
          {
            width: 170,
            field: "phoneNumber",
            headerName: "เบอร์โทรศัพท์",
            type: "phoneNumber",
          },
          { width: 200, field: "email", headerName: "อีเมล" },
          {
            width: 150,
            field: "lastActiveAt",
            headerName: "ใช้งานล่าสุด",
            type: "date",
          },
          {
            width: 160,
            field: "createdAt",
            headerName: "สร้างเมื่อ",
            hide: true,
            type: "date",
          },
          {
            width: 160,
            field: "updatedAt",
            headerName: "แก้ไขเมื่อ",
            hide: true,
            type: "date",
          },
          {
            width: 140,
            field: "role",
            headerName: "ตำแหน่ง",
            hide: true,
          },
          {
            width: 95,
            field: "actions",
            headerName: "action",
            filterable: false,
            sortable: false,
            type: "actions",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={
          props.currentUserIsSuperAdmin ? paths.adminEditPath : null
        }
        density="compact"
        autoHeight
        disableSelectionOnClick
        includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_ADMINS: gql`
    query FETCH_ADMINS {
      admins {
        code
        configs
        createdAt
        email
        firstName
        lastName
        id
        isSuperAdmin
        phoneNumber
        slug
        updatedAt
        lastActiveAt
        canDelete
      }
      # currentUser {
      #   isSuperAdmin
      # }
    }
  `,
  DELETE_ADMINS: gql`
    mutation DELETE_ADMINS($ids: [String!]!) {
      deleteAdmins(input: { ids: $ids }) {
        admins {
          code
          configs
          createdAt
          email
          firstName
          lastName
          id
          isSuperAdmin
          phoneNumber
          slug
          updatedAt
          lastActiveAt
          canDelete
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useMutation, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_ADMINS);
    const [deleteAdmins] = useMutation(API.DELETE_ADMINS);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const deleteAdmin = useCallback(
      async (params) => {
        const { id, code } = params.row;

        Modal.open({
          title: "ลบผู้ดูแลระบบ",
          children: `การดำเนินการนี้จะทำให้ผู้ดูแลระบบ ${code} ไม่สามารถเข้าใช้งานระบบได้อีก`,
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ลบ",
          onOk: async ({ close }) => {
            try {
              await deleteAdmins({ variables: { ids: [id] } });
              await refetch();
              close();
              notifySuccess("ดำเนินการลบผู้ดูแลระบบ 1 คน");
            } catch (e) {
              notifyError(e);
            }
          },
        });
      },
      [deleteAdmins, refetch]
    );

    const currentUserIsSuperAdmin = data?.currentUser?.admin?.isSuperAdmin;

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.admins.map((admin) => {
        const { firstName, lastName, isSuperAdmin, ...rest } = admin;
        return {
          ...rest,
          fullName: `${firstName || ""} ${lastName || ""}`,
          role: isSuperAdmin ? "Super Admin" : "Admin",
          actions: currentUserIsSuperAdmin
            ? [{ Icon: TrashIcon, onClick: deleteAdmin, can: !isSuperAdmin }]
            : [],
        };
      });
    }, [loading, data, error, deleteAdmin, currentUserIsSuperAdmin]);

    return {
      tableData,
      loading,
      currentUserIsSuperAdmin,
    };
  })
);

export default enhancer(AdminIndexPage);
