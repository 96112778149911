import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Backdrop, Box, Modal, Table, Typography } from "components";
import { gql, notifyError, notifySuccess, paths } from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import { map } from "lodash";
import styled from "styled-components";

const CustomDataTable = styled(Table)`
  .MuiDataGrid-cell {
    max-height: none !important;
  }

  .MuiDataGrid-row {
    max-height: none !important;
  }

  .MuiDataGrid-windowContainer {
    height: fit-content !important;

    [style="top: 39px; overflow-y: hidden;"] {
      margin-top: 39px;
    }

    [style="top: 56px; overflow-y: hidden;"] {
      margin-top: 56px;
    }

    [style="top: 72px; overflow-y: hidden;"] {
      margin-top: 72px;
    }
  }

  .MuiDataGrid-window {
    height: fit-content !important;
    position: unset !important;
  }

  .MuiDataGrid-renderingZone {
    max-height: fit-content !important;
  }

  .MuiDataGrid-viewport {
    max-height: fit-content !important;
  }

  .MuiDataGrid-window {
    height: fit-content !important;
  }
`;

const QuestionIndexPage = (props) => (
  <PageContent
    title="คำถาม"
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: null, label: "คำถาม" },
    ]}
    pageActions={[
      // {
      //   children: "Sync Data",
      //   onClick: props.syncQuestionsEvent,
      //   color: "primary",
      // },
      {
        children: "เพิ่มคำถาม",
        startIcon: <AddIcon />,
        onClick: () => paths.questionNewPath(props.courseId).push(),
        color: "primary",
      },
    ]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการคำถาม
      </Typography>
      <CustomDataTable
        columns={[
          {
            width: 140,
            field: "published",
            headerName: "สถานะ",
            type: "boolean",
          },
          { width: 200, field: "name", headerName: "ชื่อ" },
          { width: 150, field: "type", headerName: "ประเภท" },
          {
            width: 400,
            field: "tagInfo",
            headerName: "แท็ก",
            sortable: false,
            type: "tags",
            customFilterType: "selector",
            valueOptions: props.tagsOptionsForFilter,
          },
          {
            width: 300,
            field: "updatedAt",
            headerName: "วันที่แก้ไขล่าสุด",
            type: "dateTime",
          },
          {
            width: 95,
            field: "actions",
            headerName: "action",
            filterable: false,
            sortable: false,
            type: "actions",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClick={(row) =>
          paths.questionEditPath(props.courseId, row.id).push()
        }
        density="compact"
        autoHeight
        disableSelectionOnClick
        includeToolbar
        defaultPageSize={100}
        initialFilter={{
          items: [
            {
              columnField: "name",
              operatorValue: "startsWith",
              value: "",
            },
          ],
        }}
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        refetch={props.refetch}
        rowCount={props.rowCount}
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_QUESTIONS: gql`
    query FETCH_QUESTIONS(
      $page: Float
      $pageSize: Float
      $filters: JSON
      $sorts: JSON
    ) {
      questions(
        input: { filters: $filters, sorts: $sorts }
        paginate: { page: $page, pageSize: $pageSize }
      ) {
        questions {
          id
          name
          type
          tagInfo
          updatedAt
          published
        }
        paginate {
          page
          pageSize
          rowCount
        }
      }
    }
  `,
  DELETE_QUESTIONS: gql`
    mutation DELETE_QUESTIONS($ids: [String!]!) {
      deleteQuestions(input: { ids: $ids })
    }
  `,
  SYNC_QUESTIONS: gql`
    mutation SYNC_QUESTIONS {
      syncQuestions
    }
  `,
  FETCH_TAGS: gql`
    query FETCH_TAGS($input: TagsInput!) {
      tags(input: $input) {
        configs
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      useMemo,
      useQuery,
      useEffect,
      useCallback,
      useMutation,
      useUrlPath,
    } = hooks;
    const { courseId } = useUrlPath();
    const { loading, data, error, refetch } = useQuery(API.FETCH_QUESTIONS, {
      variables: {
        sorts: [{ field: "updatedAt", sort: "desc" }],
        filters: [
          {
            columnField: "course",
            operatorValue: "equals",
            value: courseId,
          },
        ],
        page: 0,
        pageSize: 100,
      },
    });
    const fetchTags = useQuery(API.FETCH_TAGS, {
      variables: {
        input: {
          course: courseId,
        },
      },
    });

    const [deleteQuestions] = useMutation(API.DELETE_QUESTIONS);
    const [syncQuestions] = useMutation(API.SYNC_QUESTIONS);

    useEffect(() => {
      if (loading) {
        Backdrop.open();
      } else {
        Backdrop.close();
      }
    }, [loading]);

    const deleteQuestion = useCallback(
      async (params) => {
        const { id } = params.row;

        Modal.open({
          title: "ลบคำถาม",
          children: `การดำเนินการนี้จะไม่สามารถย้อนกลับได้ แน่ใจใช่หรือไม่?`,
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ลบ",
          onOk: async ({ close }) => {
            try {
              await deleteQuestions({ variables: { ids: [id] } });
              await refetch();
              close();
              notifySuccess("ดำเนินการลบคำถามสำเร็จ");
            } catch (e) {
              notifyError(e.message);
              close();
            }
          },
        });
      },
      [deleteQuestions, refetch]
    );

    const tagsOptions = useMemo(() => {
      const options = [];
      const tagsData = fetchTags.data?.tags?.configs[0]?.children ?? [];
      const convertTagsDataToOptions = (
        data,
        deforeCode = [],
        deforeTitle = ""
      ) => {
        data.forEach((item) => {
          const newTitle = deforeTitle
            ? `${deforeTitle} > ${item.title}`
            : ` ${item.title}`;
          if (item.children.length > 0) {
            convertTagsDataToOptions(
              item.children,
              [...deforeCode, item.code],
              newTitle
            );
          } else {
            const newArray = [...deforeCode];
            const first = newArray.shift();

            options.push({
              label: `${
                first +
                (item.difficultyLevel
                  ? item.difficultyLevel.toString().padStart(2, "0")
                  : "") +
                newArray.join("") +
                item.code
              } - ${newTitle}`,
              value: item.id,
            });
            return null;
          }
        });
      };
      convertTagsDataToOptions(tagsData, []);
      return options;
    }, [fetchTags.data]);

    const tagsOptionsForFilter = useMemo(
      () =>
        map(tagsOptions, (value) => ({
          label: value.label,
          value: value.value,
        })),
      [tagsOptions]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.questions.questions.map((question) => {
        const { ...rest } = question;
        return {
          ...rest,
          tagsOptions,
          actions: [
            { Icon: TrashIcon, onClick: deleteQuestion, can: rest.canDelete },
          ],
        };
      });
    }, [loading, data, error, deleteQuestion, tagsOptions]);

    const syncQuestionsEvent = useCallback(async () => {
      try {
        await syncQuestions();
        await refetch();
        notifySuccess("ดำเนินการลบคำถามสำเร็จ");
      } catch (e) {
        notifyError(e);
      }
    }, [syncQuestions, refetch]);

    return {
      courseId,
      tableData,
      loading,
      syncQuestionsEvent,
      tagsOptions,
      tagsOptionsForFilter,
      rowCount: data?.questions?.paginate?.rowCount ?? 0,
      refetch,
    };
  })
);

export default enhancer(QuestionIndexPage);
